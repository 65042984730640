import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import jwt_decode from "jwt-decode";
import Popup from 'reactjs-popup';
import { ThemeContext } from '../App/ThemeContext';
import 'reactjs-popup/dist/index.css';
import { apiUrl } from '../App/Constants';

export default function Teams() {
  const isMobile = /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  const { theme } = useContext(ThemeContext);
  const primaryColor = theme.primary;
    const tableStyles = {
        display: "flex",
        flexDirection: "column",
      };
      const bonusinput = {
        borderRadius: 10,
        border: "1px solid black",
      };
      
      const rowStylesOrange = {
        fontSize: "15px",
        display: isMobile ? "block" : "flex",
        justifyContent: "space-between",
        padding: "20px",
        borderRadius: "10px",
        margin: "5px",
        alignItems: "center",
        backgroundColor: "#fdcb6e"
      };
      const rowStylesGrey = {
        fontSize: "15px",
        display: isMobile ? "block" : "flex",
        justifyContent: "space-between",
        padding: "20px",
        borderRadius: "10px",
        margin: "5px",
        alignItems: "center",
        backgroundColor: "grey"
      };
      const rowStylesRed = {
        fontSize: "15px",
        display: isMobile ? "block" : "flex",
        justifyContent: "space-between",
        padding: "20px",
        borderRadius: "10px",
        margin: "5px",
        alignItems: "center",
        backgroundColor: "#ff7675"
      };
      const rowStylesGreen = {
        fontSize: "15px",
        display: isMobile ? "block" : "flex",
        justifyContent: "space-between",
        padding: "20px",
        borderRadius: "10px",
        margin: "5px",
        alignItems: "center",
        backgroundColor: "#00b894",
        color: "white"
      };
      
      const columnStyles = {
        flexBasis: "10%",
      };
      const headerStyles = {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
        fontWeight: 'bold',
        padding: '10px',
        color: 'black',
        textAlign: 'center',
        fontSize: '14px'
      };
      
  const [allData, setAllData] = useState([])
  const [promoters, setAllPromoters] = useState([])
  const [allpromoters, setAllThePromoters] = useState([])
  const [bonusName, setBonusName] = useState('');
  const [bonusPrice, setBonusPrice] = useState('');
  const [bonuses, setBonuses] = useState([]);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const [selectedMonth, setSelectedMonth] = useState(`2024-${currentMonth.toString().padStart(2, '0')}`)
  const isCurrentMonthSelected = selectedMonth === `2024-${currentMonth.toString().padStart(2, '0')}`;
  const currentDateMonth = new Date("2024-03");
  const juniorSeniorDate = new Date("2024-07");
  const [me, setMe] = useState({});
  const [selectedPromoters, setSelectedPromoters] = useState([]);
  const [showPopup, setShowPopup] = useState(false);

const inputDate = new Date(selectedMonth);
    const isAfterNovember2023 = currentYear > 2023 || (currentYear === 2023 && currentMonth > 10);
    const isAfterFebruar2024 = currentYear > 2024 || (currentYear === 2024 && currentMonth > 2);

  useEffect(() => {
    fetchData(selectedMonth);
    fetchBonuses(selectedMonth);
    fetchPromoters();
}, [selectedMonth]);

const handleCheckboxChange = (promoterId) => {
    setSelectedPromoters((prevSelected) =>
      prevSelected.includes(promoterId)
        ? prevSelected.filter((id) => id !== promoterId)
        : [...prevSelected, promoterId]
    );
  };

  const handleSubmit = () => {
    fetch(apiUrl + 'promoter.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem("jwt")
      },
      body: JSON.stringify({ promoters: selectedPromoters, teamid: me.id }),
    })
      .then((response) => response.json())
      .then((data) => {
        setShowPopup(false);
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
// Function to get all unique dates for a user

function getUniqueDates(dataArray) {
    const dateSet = new Set(dataArray.map(item => item.time.split(" ")[0]));
    return Array.from(dateSet);
  }
  // Generate a list of months from January 2023 to the current month
  const monthsList = [];
  for (let year = 2023; year <= currentYear; year++) {
    const maxMonth = year === currentYear ? currentMonth : 12;
    for (let month = 1; month <= maxMonth; month++) {
      const monthValue = month.toString().padStart(2, '0');
      monthsList.push(`${year}-${monthValue}`);
    }
  }

// Function to group objects by a given key
function groupBy(array, key) {
    return array.reduce((result, obj) => {
      const keyValue = obj[key];
      (result[keyValue] = result[keyValue] || []).push(obj);
      return result;
    }, {});
  }
  const fetchPromoters = () => {
    if (localStorage.getItem("isUserLoggedIn")) {
    var options = {  
    method: 'GET',
    headers: {
      
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem("jwt")
    }
    }
    var url = apiUrl + "promoter.php"
    fetch(url, options)
        .then(response => {
        return response.json()
        })
        .then(data => {
            var decodedUser = jwt_decode(localStorage.getItem("jwt"));
            let me = data.filter(item => item.real_name == decodedUser.name)[0]
             setMe(me);
        setAllPromoters(data.filter(item => item.username !== "Super Slimmer" && item.username !== "Slim Store" && item.username !== "László Emma" && item.username !== "Zentai Zsófi" && item.username !== "Super Slimmer").filter(item => item.jobrole != "intern").filter(user => user.isActive == 1).filter(user => user.teamid == me.teamid));
        setAllThePromoters(data.filter(item => item.username !== "Super Slimmer" && item.username !== "Slim Store" && item.username !== "László Emma" && item.username !== "Zentai Zsófi" && item.username !== "Super Slimmer").filter(item => item.jobrole != "intern").filter(user => user.isActive == 1));
        
    })
        .catch(error => {
            // window.location = '/login'
        })
    } else {
    window.location = '/login'
    }
}
const isSelected = (promoterId) => {
    return selectedPromoters.includes(promoterId) || promoters.some((promoter) => parseInt(promoter.id) === parseInt(promoterId));
    }
const handleAddBonus = (id) => {
  if (localStorage.getItem("isUserLoggedIn") === "true") {
    
  var options = {  
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("jwt")
    }
  }
  var url = apiUrl + "promoterbonus.php?yearmonth="+selectedMonth+"&bonustitle="+bonusName+"&bonusvalue="+bonusPrice+"&promoterid="+id
    fetch(url, options)
      .catch(error => {
       window.location = '/login'
      }).then(response => {
        if(response.status == 200){
          window.alert("Sikeres bónusz hozzáadás")
        window.location.reload();
        }
      })
    
  } else {
    window.location = '/login'
  }
};
const fetchBonuses = (time) => {
  var options = {  
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("jwt")
    }
  }
  var url2 = apiUrl + "promoterbonus.php?yearmonth=" + time
  fetch(url2, options)
    .then(response2 => {
      return response2.json()
    })
    .then(data2 => {
      setBonuses(data2);
    })
}
const fetchData = (time) => {
    if (localStorage.getItem("isUserLoggedIn") === "true") {
      var decoded = jwt_decode(localStorage.getItem("jwt"));
    var options = {  
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem("jwt")
      }
    }
    var url = apiUrl + "storage.php?isMasters=true&month=" + time
      fetch(url, options)
        .then(response => {
          return response.json()
        })
        .then(data => {
          
            // Group the objects by the "userName" key
const groupedArray = groupBy(data, "userName");

// Convert the grouped object into an array of objects
const newArray = Object.entries(groupedArray).map(([key, value]) => ({ [key]: value }));

setAllData(newArray);
        })
        .catch(error => {
         window.location = '/login'
        })
      
    } else {
      window.location = '/login'
    }
    
    
  }
  function calculateTotalValue(userData) {
    return userData.reduce((acc, item) => acc + parseInt(item.price), 0);
  }
// Function to group data by unique dates and calculate sums for each promoter
function groupDataByUniqueDates(sortedAllData) {
  const groupedData = {};

  sortedAllData.forEach((data) => {
    const { username, userData } = data;
    const userGroup = {};

    userData.forEach((item) => {
      const date = item.time.split(' ')[0]; // Extract the date from the "time" field
      const totalValue = calculateTotalValue([item]);

      if (userGroup[date]) {
        userGroup[date].totalValue += totalValue;
      } else {
        userGroup[date] = { date, username, totalValue };
      }
    });

    Object.values(userGroup).forEach((userItem) => {
      if (groupedData[userItem.username]) {
        groupedData[userItem.username].push(userItem);
      } else {
        groupedData[userItem.username] = [userItem];
      }
    });
  });

  return groupedData;
}

  const handleMonthChange = (event) => {
    setAllData([]);
    setSelectedMonth(event.target.value);
  };
  // Function to create day-based summed array
function createSummedArray(dataArray) {
  const summedArray = [];

  dataArray.forEach((item) => {
    const date = item.time.split(' ')[0]; // Extract the date from the "time" field
    const price = parseInt(item.price);

    const existingEntry = summedArray.find((entry) => entry.date === date);
    if (existingEntry) {
      existingEntry.sum += price;
    } else {
      summedArray.push({ date, sum: price });
    }
  });

  return summedArray;
}
  // Calculate the totalValue/7000 and sort the allData array
const sortedAllData = allData.map(data => {
  var value = 6000;
  if (selectedMonth < "2023-07") {
    value = 6000
  } else {
    value = 7000
  }
  if (selectedMonth > "2023-10") {
    value = 8000
  }
  if (selectedMonth == "2023-07") {
    value = 6300
  }
  if (selectedMonth == "2023-10") {
    value = 7400
  }
  if (selectedMonth > "2023-11") {
    value = 10000
  }
  if (selectedMonth > "2024-05") {
    value = 12000
  }
    const username = Object.keys(data)[0];
    const userData = data[username];
    const dateSums = createSummedArray(userData);

    var totalize = 0.00;
    dateSums.forEach((item) => {
      if(item.date < '2023-07-25'){

        totalize += item.sum/6000
      }
      if(item.date < '2023-10-10'){

        totalize += item.sum/7000
      } 
      if (item.date < '2023-12-01'){
        totalize += item.sum/8000
      } else {
        if (item.date < '2024-06-01'){
          totalize += item.sum/10000
        } else {
          totalize += item.sum/12000
        }
      }
    })
    const totalValue = calculateTotalValue(userData);
    const totalValueNormalized = parseInt(totalize.toFixed(2));
    const totalValueNormalizedByDates = parseInt((totalValueNormalized / getUniqueDates(userData).length).toFixed(2));
  
    return { username, userData, totalValueNormalized, totalValueNormalizedByDates, dateSums };
  }).sort((a, b) => b.totalValueNormalized - a.totalValueNormalized); // Sort in descending order

  const groupedData = groupDataByUniqueDates(sortedAllData);

  function lakasTamogatas (e) {
    if(inputDate < currentDateMonth){if(isAfterNovember2023) {
      if(e > 1000){
        return 600000
      }
      if(e > 900){
        return 500000
      }
      if(e > 800){
        return 400000
      }
      if(e > 700){
        return 300000
      }
      if(e > 600){
        return 200000
      }
      if(e > 500){
        return 100000
      }
      if(isCurrentMonthSelected){
        return 0
      } else {
        return 0
      }
    } else {
      if(e > 1000){
        return 200000
      }
      if(e > 900){
        return 170000
      }
      if(e > 800){
        return 150000
      }
      if(e > 700){
        return 120000
      }
      if(e > 600){
        return 100000
      }
      if(isCurrentMonthSelected){
        return 0
      } else {
        return 0
      }
    } } else {
      return 0
    }
    
    
    
  }
  // Helper function to calculate the number based on the formula
function calculateNumber(date, totalValue) {
  const result = date < '2023-07-25' ? totalValue / 6000 : date < '2023-10-10' ? totalValue / 7000 : date < '2023-12-01' ? totalValue / 8000 :  date < '2024-06-01' ? totalValue / 10000 :  totalValue / 12000;
  if (result >= 0 && result < 30) {
    return result * 200;
  } else if (result >= 30 && result < 40) {
    return result * 300;
  } else if (result >= 40 && result < 50) {
    return result * 500;
  } else if (result >= 50 && result < 60) {
    return result * 800;
  } else if (result >= 60 && result < 70) {
    return result * 900;
  } else if (result >= 70 && result < 80) {
    return result * 1000;
  } else if (result >= 80 && result < 90) {
    return result * 1100;
  } else if (result >= 90 && result < 100) {
    return result * 1200;
  } else {
    return result * 1300;
  }
}
const formatPrice = (value) => {
  // Convert the plain number to a numeric value
  const numericValue = parseFloat(value);
  // Check if the numeric value is a valid number
  if (!isNaN(numericValue)) {
    // Use toLocaleString to format the number with spaces as thousand separators
    return numericValue.toLocaleString('hu');
  } else {
    // Return the original value if it's not a valid number
    return value;
  }
};
function helyezetDij (e) {
  switch (sortedAllData.filter(item => item.username !== "Slim Store" || item.username !== "Super Slimmer" || item.username !== "László Emma" && item.username !== "Zentai Zsófi" && item.username !== "Super Slimmer").findIndex(item => item === e)) {
    case 0:
      return !isCurrentMonthSelected ? "Első helyért járó díj: br. 100.000 Ft" : "";
    case 1:
      return !isCurrentMonthSelected ? selectedMonth > "2023-11" ? "Második helyért járó díj: br. 60.000 Ft" : "" : "";
    case 2:
      return !isCurrentMonthSelected ?  selectedMonth > "2023-11" ? "Harmadik helyért járó díj: br. 30.000 Ft" : "" : "";
    default:
      return "";
  }
  }
function helyezettOsszeg(e){
  switch (sortedAllData.filter(item => item.username !== "Slim Store" || item.username !== "Super Slimmer" || item.username !== "László Emma" && item.username !== "Zentai Zsófi" && item.username !== "Super Slimmer").findIndex(item => item === e)) {
    case 0:
      return !isCurrentMonthSelected ? 100000 : 0;
    case 1:
      return !isCurrentMonthSelected ? selectedMonth > "2023-11" ? 60000 : 0 : 0;
    case 2:
      return !isCurrentMonthSelected ?  selectedMonth > "2023-11" ? 30000 : 0 : 0;
    default:
      return 0;
  }
}
function fizetes (groupedData, jobrole, totalValue, foundUser) {
  var value = 0;
  if (inputDate >= juniorSeniorDate) {
    if(foundUser["fulltime"] == "senior") {
      value = 1000000;
      if (totalValue > 499) {
        value += (totalValue - 400)*3000;
      } else if (totalValue > 449) {
        value += (totalValue - 400)*2500;
      } else if (totalValue > 399) {
        value += (totalValue - 400)*2000;
      }
    } else if (foundUser["fulltime"] == "junior" && jobrole != "parttime") {
      value = 600000;
      if (totalValue > 499) {
        value += (totalValue - 300)*2500;
      } else if (totalValue > 449) {
        value += (totalValue - 300)*2000;
      } else if (totalValue > 399) {
        value += (totalValue - 300)*1500;
      } else if (totalValue > 299) {
        value += (totalValue - 300)*1000;
      }
    } else {
      if (jobrole === "parttime") {
        value = 400000;
        if (totalValue > 399) {
          value += (totalValue - 200)*2500;
        } else if (totalValue > 349) {
          value += (totalValue - 200)*2000;
        } else if (totalValue > 299) {
          value += (totalValue - 200)*1500;
        } else if (totalValue > 200) {
          value += (totalValue - 200)*1000;
        }
      }
    }
      
    
}
else {
  if (inputDate >= currentDateMonth) {
    if(jobrole === "fulltime"){
        value = 600000;
      if (totalValue > 499) {
        value += (totalValue - 300)*2000;
      } else if (totalValue > 449) {
        value += (totalValue - 300)*2000;
      } else if (totalValue > 399) {
        value += (totalValue - 300)*1500;
      } else if (totalValue > 300) {
        value += (totalValue - 300)*1000;
      }
     
    } else if (jobrole === "parttime") {
      value = 400000;
      if (totalValue > 399) {
        value += (totalValue - 200)*2500;
      } else if (totalValue > 349) {
        value += (totalValue - 200)*2000;
      } else if (totalValue > 299) {
        value += (totalValue - 200)*1500;
      } else if (totalValue > 200) {
        value += (totalValue - 200)*1000;
      }
    } else if (jobrole === "intern") {
      value = 20000 * groupedData.length
    }
    
  } else {
    groupedData.forEach((item) => {
      value += calculateNumber(item.date, item.totalValue) + 30000
    })
  }
}
  return value
  }
  const getJobName = (jobrole, foundUser) => {
    if(inputDate < juniorSeniorDate){
    switch (jobrole) {
      case "fulltime":
        return "Teljes munkaidős"
      case "parttime":
        return "Részmunkaidős"
      case "intern":
        return "Betanuló" + " - " + internPhase(foundUser) + ". fázis"
      default:
        return "Inaktív"
    }
  } else {
    switch (foundUser["fulltime"]) {
      case "senior":
        return "Senior értékesítő"
      case "junior":
        return "Junior értékesítő F"
      case "none":
        return "Junior értékesítő M"
      default:
        return "Inaktív"
    }
  }
  }
  const internPhase = (foundUser) => {
    if(foundUser == null){
      return;
    } else {
    
    const today = new Date();
    const date = new Date(foundUser.created_at);
    const timeDifference = today - date;
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    if (daysDifference < 10) {
    return 1;
  } else if (daysDifference >= 10 && daysDifference < 20) {
    return 2;
  } else {
    return 3;
  }
}
  }
  const getBonuses = (id) => {
    var bonus = bonuses.filter(item => item.promoterid == id);
    if(bonus.length > 0){
      return (
        <div>
          {bonus.map((item) => (
            <div className='bonustile' onClick={ () => handleBonusDelete(item["id"])}>
              {item["bonustitle"] + ": " + item["bonusvalue"] + " Ft"}
            </div>
          ))}
        </div>
      );
    }}
    const getBonusValues = (id) => {
      var bonusValue = 0;
      var bonus = bonuses.filter(item => item.promoterid == id);
      if(bonus.length > 0){
        
        {bonus.map((item) => (
          bonusValue += parseInt(item["bonusvalue"])
        ))}
        
      }
      return bonusValue
    }
    function handleBonusDelete(id) {
      var decoded = jwt_decode(localStorage.getItem("jwt"));
  var isAdmin = decoded.priv == 1 ? true : false;
  if (isAdmin) {
    if(window.confirm("Biztosan törölni szeretnéd a bónuszt?")) {
      if (localStorage.getItem("isUserLoggedIn") === "true") {
        var options = {  
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem("jwt")
          }
        }
        var url = apiUrl + "promoterbonus.php?removeID=" + id
        fetch(url, options)
          .catch(error => {
           window.location = '/login'
          }).then(response => {
            if(response.status == 200){
              window.alert("Sikeres bónusz törlés")
            window.location.reload();
            }
          })
        
      } else {
        window.location = '/login'
      }
    }
  }
      
      
    }
  function getColorByJob(jobrole, isAfterFebruar2024, isAfterNovember2023, totalValueNormalizedByDates, userData) {
    if (getJobName(jobrole, userData) !== "Inaktív") {
        if (isAfterFebruar2024) {
          if(jobrole === "intern"){
            if (internPhase(userData) === 1) {
              return totalValueNormalizedByDates < 10 ? rowStylesRed : rowStylesGreen;
            } else if (internPhase(userData) === 2) {
              return totalValueNormalizedByDates < 15 ? rowStylesRed : rowStylesGreen;
            } else {
              return totalValueNormalizedByDates < 20 ? rowStylesRed : rowStylesGreen;
            }
          } else {
            return totalValueNormalizedByDates < 20 ? rowStylesRed :
            totalValueNormalizedByDates >= 25 ? rowStylesGreen :
            rowStylesOrange;
          }
            
        } else if (isAfterNovember2023) {
            return totalValueNormalizedByDates < 20 ? rowStylesRed :
                   totalValueNormalizedByDates >= 31 ? rowStylesGreen :
                   rowStylesOrange;
        } else {
            return totalValueNormalizedByDates < 32 ? rowStylesRed :
                   totalValueNormalizedByDates >= 38 ? rowStylesGreen :
                   rowStylesOrange;
        }
    } else {
        return rowStylesGrey;
    }
}
  const renderPopupforDays = (alldataofValue) => {
    if(alldataofValue[0].sum != null){
      return (
        <div>
        {alldataofValue.map((day) => (
          <div className='row'>
          <div className='column leftcolumn'>
          {day["date"]}
          </div>
          <div className='column'>
            {parseInt((day["sum"]/(day.date < '2023-07-25' ? 6000 : day.date < '2023-10-10' ? 7000 : day.date < '2023-12-01' ? 8000 : day.date < '2024-06-01' ? 10000 : 12000)).toFixed(2))}
          </div>
          </div>
        ))}
        </div>
      );
    } else {
      return (
        <div>
        {alldataofValue.map((day) => (
          <div className='row'>
          <div className='column leftcolumn'>
          {day["date"]}
          </div>
          <div className='column'>
          {"br. " + formatPrice(calculateNumber(day["date"], day["totalValue"])+30000) + " Ft"}
          </div>
          </div>
        ))}
        </div>
      );
    }
      
    
    
  };
  var decoded = jwt_decode(localStorage.getItem("jwt"));
  var isAdmin = decoded.priv == 1 ? true : false;
  return(
    // Rendering the sorted table-like layout
<div id='masters'>
  <h2 style={{ color: primaryColor}}>Csapatom</h2>
  <select className='mobileInput' value={selectedMonth} onChange={handleMonthChange}>
        {monthsList.map((month) => (
          <option key={month} value={month}>
            {month}
          </option>
        ))}
      </select>
{me.teamlead == 1 && (
    <button className='mobileInput' onClick={() => setShowPopup(true)}>Csapatom kezelése</button>
)}
{showPopup && (
        <div className="popup">
            <h3>Válaszd ki a csapatod embereit</h3>
              {allpromoters.map((promoter) => (
                <div>
                  <label>
                    <input
                      type="checkbox"
                      value={promoter.id}
                      onChange={() => handleCheckboxChange(promoter.id)}
                      checked={isSelected(promoter.id)}
                    />
                    {promoter.username}
                  </label>
                  </div>
              ))}
            <button onClick={handleSubmit}>Mentés</button>
            <button onClick={() => setShowPopup(false)}>Vissza</button>
          
        </div>
      )}
  <div style={tableStyles}>
  <div style={headerStyles} className='onlyonPC'>
    <div></div>
    <div>Név</div>
    <div>Összes</div>
    <div>Átlag/nap</div>
    <div>Fizetés</div>
    <div>Bónusz</div>
  </div>
  {sortedAllData.length === 0 && (
        <div className="loader" style={{ borderTopColor: primaryColor}} />
      )}
    {sortedAllData.map((data, index) => {
      const { username, userData, totalValueNormalized, totalValueNormalizedByDates, dateSums } = data;
      const foundUser = promoters.find(obj => obj.username === username.replace(/^\s+|\s+$/g, ''));
      var profilePic = apiUrl + "profile_images/placeholder.png";
      if(foundUser != null && foundUser.profile_pic != ""){
        profilePic = apiUrl + "profile_images/" + foundUser.profile_pic;
      }
      if (username !== "Webshop" && username !== "Slim Store" && foundUser != undefined && foundUser.jobrole != "intern" && username !== "László Emma" && username !== "Zentai Zsófi") {
        var jobrole = "";
        if (foundUser!= null && foundUser.jobrole!= null && foundUser.jobrole!= "" ) {
          jobrole = foundUser.jobrole
        }
        return (
          <div key={index} className={sortedAllData[0] == data ? !isCurrentMonthSelected ? 'first-row' : '' : ''} style={getColorByJob(jobrole, isAfterFebruar2024, isAfterNovember2023, totalValueNormalizedByDates, foundUser)}>
            <a href={foundUser ? `/guestprofile/${foundUser.id}` : '#'}>
            <div>
              {foundUser ? <img className='profilepicinMasters' src={profilePic} alt="Profile Picture" /> : <img className='profilepicinMasters' src={profilePic} alt="Profile Picture" />}
            </div>
          </a>
            <div style={columnStyles}>{username}  {sortedAllData[0] == data ? !isCurrentMonthSelected ? '👑' : '': ''}</div>
            <div>({getJobName(jobrole, foundUser)})</div>
            <hr className='hrc onlyonMobile'/>
            <div ><p className='onlyonMobile'>Összesen: </p>{totalValueNormalized} db<Popup trigger={<button className='infobutton morebutton'>
            <FontAwesomeIcon className='faiconinfo' icon={faInfoCircle} /> Részletes
            </button>} modal>
        <div>{renderPopupforDays((dateSums))}</div>
        </Popup></div>
        <hr className='hrc onlyonMobile'/>
            <div ><p className='onlyonMobile'>Átlag/nap: </p>{totalValueNormalizedByDates} db</div>
            <hr className='hrc onlyonMobile'/>
            <div ><p className='onlyonMobile'>Fizetés: </p>
              {Date(inputDate) > Date(currentDateMonth) ? (jobrole != "intern" ? "br. " : "net. ") : "br. " + formatPrice(fizetes((groupedData[username]), jobrole, totalValueNormalized, foundUser)+helyezettOsszeg(data)+ lakasTamogatas(totalValueNormalized) + getBonusValues(foundUser!= null ? foundUser.id : 0) ) + " Ft"}
              <Popup trigger={inputDate < currentDateMonth ? <button className='infobutton morebutton'>
            <FontAwesomeIcon className='faiconinfo' icon={faInfoCircle} /> Részletes
            </button> : <b></b>} modal>
        <div>{renderPopupforDays((groupedData[username]))}</div>
        <hr className='hrc onlyonMobile'/>
        </Popup>
            </div>
            <hr className='hrc onlyonMobile'/>
            <div style={columnStyles}><p className='onlyonMobile'>Bónusz: </p>
              {inputDate < currentDateMonth ? lakasTamogatas(totalValueNormalized) > 0 ? "br. " + formatPrice(lakasTamogatas(totalValueNormalized))+ " Ft" : isCurrentMonthSelected ? "Még nincs" : "Nincs" : ""}
              <br></br>
              {helyezetDij(data)}
              <div>{ getBonuses(foundUser != undefined ? foundUser.id : "0")}</div>
              <Popup trigger={jobrole != "" && isAdmin && <button className='morebutton infobutton'>Bónusz hozzáadás</button>} nested>
              <div className="modal">
          <div className="modal-content">
            <h2>Bónusz hozzáadása</h2>
            <div style={{textAlign:"left"}}>
            <label>Bónusz megnevezése</label>
            
            <input 
            style={bonusinput}
              type="text" 
              value={bonusName} 
              onChange={(e) => setBonusName(e.target.value)} 
            />
            <br></br>
            <label>Bónusz összege</label>
            <input 
            style={bonusinput}
              type="number" 
              value={bonusPrice} 
              onChange={(e) => setBonusPrice(e.target.value)} 
            />
            </div>
            <br></br>
            <button style={{width:"100%", height:"35px", "marginTop": "px"}} onClick={() => handleAddBonus(foundUser.id)}>Bónusz hozzáadása</button>
          </div>
        </div>
        </Popup>
            </div>
          </div>
        );
      }
      
    })}
  </div>
</div>
  )

}


