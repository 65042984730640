import React, { useState, useEffect } from 'react';
import 'reactjs-popup/dist/index.css';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { apiUrl } from '../App/Constants';


export default function Raktarkeszlet() {
    const [itemList, setList] = useState([])
    const [items, setItem] = useState([])
  useEffect(() => {
    fetchData()
}, []);

const fetchData = () => {
    if (localStorage.getItem("isUserLoggedIn")) {
        fetch(apiUrl + "list.php")
            .then(response => {
                return response.json();
            })
            .then(data => {
                setList(data.itemTypes);
                var items = [];
                var options = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem("jwt")
                    }
                };
                var url = apiUrl + "raktarkeszlet.php";
                fetch(url, options)
                    .then(response => {
                        return response.json();
                    })
                    .then(raktarkeszletData => {
                        // Iterate through the list of items and check if they exist in raktarkeszletData
                        console.log(raktarkeszletData)
    
                        data.itemTypes.forEach(item => {
                            var foundItem = raktarkeszletData.find(raktarItem => raktarItem.itemName === item.itemName);
                            if (data.phoneTypes != [""]) {
                              console.log("ok");
                            }
                            if (foundItem) {
                                items.push(foundItem);
                            }
                        });
                        setItem(raktarkeszletData);
                    })
                    .catch(error => {
                        window.location = '/login';
                    });
            })
            .catch(error => {
                window.location = '/login';
            });
    } else {
        window.location = '/login';
    }
}

const saveData = (itemName, phoneType) => (e) => {
    if (localStorage.getItem('isUserLoggedIn')) {
      const parentElement = e.target.parentNode;
      const inputElement = parentElement.querySelector('input[type="number"]');
      if (inputElement) {
        const qty = inputElement.value;
        axios.post(apiUrl + 'raktarkeszlet.php?itemName='+ itemName +'&phoneType=' + phoneType + '&qty=' + qty).then(fetchData())
          .catch(error => {
            alert("Sikertelen mentés!");
          return;
        });
        
      }
    } else {
      window.location = '/login';
    }
  };
  const findQtyByCriteria = (itemList, itemName, phoneType) => {
    const foundItem = itemList.find(
      (item) => item.itemName === itemName && item.phoneType === phoneType
    );
      console.log(itemList);
    if (foundItem) {
      return foundItem.qty;
    } else {
      return null; // Vagy azt, amit a keresés nem talált esetén vissza szeretnél adni
    }
  };
  return (
    <div>
        <h2>Raktárkészlet</h2>
        {itemList.map((val, key) => {
            if (val.itemName != "Üvegfólia") {
                return (
                    <div className='itemName'>
                        <h2>{val.itemName}</h2>
                    <div >{JSON.parse(val.phoneTypes).map((vali, key) => {
                        if(vali != "-"){
                            return (
                                <div className='itemType' key={key}>
                                <p>{vali}<input type='number' className="numberinput" defaultValue={findQtyByCriteria(items, val.itemName, vali)}></input> db</p>
                                <button className='inputButtonType' onClick={saveData(val.itemName, vali)}>Mentés</button>
                                </div>
                            )
                        } else {
                            return(
                            <div className='itemType' key={key}>
                              <p><input type='number' className="numberinput" defaultValue={findQtyByCriteria(items, val.itemName, vali)}></input> db</p>
                                <button className='inputButtonType' onClick={saveData(val.itemName, vali)}>Mentés</button>
                                </div>
                                )
                        }
                        
                    })}</div>
                    </div>
                )
            }
                        
        })}
    </div>
  );

}

