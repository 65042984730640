import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { apiUrl } from '../App/Constants';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  TimeScale,
  Filler
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  Filler,
  zoomPlugin
);

const ChartComponent = () => {
  const [chartData, setChartData] = useState({});
  const [chartType, setChartType] = useState('bar');
  const [error, setError] = useState(null);
  const [condition, setCondition] = useState("");
  const [sendData, setSendData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Dynamic Chart',
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy',
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(apiUrl + 'aistat.php', { condition: condition });
        if (response.data.error) {
          setError(response.data.error);
        } else {
          const processedData = processUnknownData(response.data["query_result"]);
          setChartData(processedData.data);
          setChartType(processedData.chartType);
          setIsLoading(false);
        }
      } catch (error) {
        setError(error.message);
      }
    };
    if (condition !== "") {
      fetchData();
    }
  }, [sendData]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  const processUnknownData = (data) => {
    if (!Array.isArray(data) || data.length === 0) return { data: { labels: [], datasets: [] }, chartType: 'bar' };
  
    const keys = Object.keys(data[0]);
    const labels = data.map(item => item[keys[0]]);
  
    // Ellenőrizzük, van-e dátum formátumú adat a tömbben
    const isDatePresent = keys.some(key => {
      return data.some(item => item[key].includes('2023') || item[key].includes('2024') || item[key].includes('Monday') || item[key].includes('Tuesday') || 
      item[key].includes('Wednesday') || item[key].includes('Thursday') || item[key].includes('Friday') || item[key].includes('Saturday') || 
      item[key].includes('Sunday') );
    });
  
    const datasets = keys.slice(1).map((key, index) => {
      const color = `rgba(${75 + (index * 50) % 256}, ${192 - (index * 30) % 256}, ${192 - (index * 50) % 256}, 0.2)`;
      const borderColor = `rgba(${75 + (index * 50) % 256}, ${192 - (index * 30) % 256}, ${192 - (index * 50) % 256}, 1)`;
  
      return {
        label: key,
        data: data.map(item => Number(item[key]) || 0),
        backgroundColor: color,
        borderColor: borderColor,
        borderWidth: 1,
      };
    });
  
    // Ellenőrizzük, ha az adatokban nincs dátum és az adattömb egyszerű számértékeket tartalmaz
    const isSimpleNumericObject = keys.slice(1).every(key => !isNaN(Number(data[0][key])));
    // Ha nincs dátum és egyszerű számadatok vannak, legyen pie chart
    if (!isDatePresent) {
      const pieData = data[1];
      const pieLabels = keys.slice(1);
      const pieValues = pieLabels.map(key => Number(pieData[key]));
  
      return {
        data: {
          labels: pieLabels,
          datasets: [{
            data: pieValues,
            backgroundColor: pieLabels.map((_, index) => `rgba(${75 + (index * 50) % 256}, ${192 - (index * 30) % 256}, ${192 - (index * 50) % 256}, 0.2)`),
            borderColor: pieLabels.map((_, index) => `rgba(${75 + (index * 50) % 256}, ${192 - (index * 30) % 256}, ${192 - (index * 50) % 256}, 1)`),
            borderWidth: 1,
          }]
        },
        chartType: 'pie'
      };
    }
  
    return { data: { labels, datasets }, chartType: datasets.length > 1 ? 'line' : 'bar' };
  };

  if (!chartData.labels) {
    return (
      <div>
        <input type="text" value={condition} onChange={(e) => setCondition(e.target.value)} />
        <button onClick={() => setSendData(!sendData)}>Fetch Data</button>
        {isLoading && <div>Loading...</div>}
      </div>
    );
  }

  return (
    <div>
      <input type="text" value={condition} onChange={(e) => setCondition(e.target.value)} />
      <button onClick={() => setSendData(!sendData)}>Fetch Data</button>
      {isLoading && <div>Loading...</div>}
      {!isLoading && chartType === 'bar' && <Bar data={chartData} options={options} />}
      {!isLoading && chartType === 'line' && <Line data={chartData} options={options} />}
      {!isLoading && chartType === 'pie' && <Pie data={chartData} options={options} />}
    </div>
  );
};

export default ChartComponent;