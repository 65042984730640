import React, { useState, useEffect, useContext } from 'react';
import ImageUploadComponent from "../Profile/Imageupload";
import chroma from 'chroma-js';
import { ThemeContext } from '../App/ThemeContext';

const isValidColor = (color) => {
  const contrast = chroma.contrast(color, 'white');
  return contrast >= 4.5;
};
export default function ProfilePage() {
  const { theme, setThemeColor } = useContext(ThemeContext);

  const handleColorChange = (event) => {
    const newColor = event.target.value;
    setThemeColor(newColor);
  };
  return(
    <div>
     <h3>Profil</h3>
     {<ImageUploadComponent/>}
     <div>
      <label htmlFor="primary" className='inputLabel'>Elsődleges szín</label>
      <br></br>
      <br></br>
      <input type="color" value={theme.primary} onChange={handleColorChange} />
    </div>
    </div>
  );
}
