import React, { useState } from 'react';
import { apiUrl } from './Constants';

function CouponGenerator() {
  const [email, setEmail] = useState('');
  const [qty, setQty] = useState(5);
  const [responseMessage, setResponseMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(apiUrl + `postcoupon.php?email=${email}&qty=${qty}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setResponseMessage(data.message);
      setEmail('');
    } catch (error) {
      console.error('Error generating coupons:', error);
      setResponseMessage('Hiba a tombola generálás közben.');
    }
  };

  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h2>Tombola generátor</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={{ margin: '10px', padding: '5px' }}
          />
        </div>
        <div>
          <label>Tombola darab:</label>
          <input
            type="number"
            value={qty}
            onChange={(e) => setQty(e.target.value)}
            min="1"
            style={{ margin: '10px', padding: '5px' }}
          />
        </div>
        <button type="submit" style={{ padding: '10px 20px', marginTop: '20px' }}>Tombola kiállítása</button>
      </form>
      {responseMessage && <p>{responseMessage}</p>}
    </div>
  );
}

export default CouponGenerator;