// List.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Garancia.css';
import { apiUrl } from '../App/Constants';

const List = () => {
  const [imeiSearch, setImeiSearch] = useState('');
  const [data, setData] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    fetchData();
    fetchPromoters();
  }, []);

  const openModal = (images) => {
    setSelectedImages(images);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [promoters, setAllPromoters] = useState([])

  const fetchPromoters = () => {
    if (localStorage.getItem("isUserLoggedIn")) {
    var options = {  
    method: 'GET',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem("jwt")
    }
    }
    var url = apiUrl + "promoter.php"
    fetch(url, options)
        .then(response => {
        return response.json()
        })
        .then(data => {
        setAllPromoters(data)
        })
        .catch(error => {
            window.location = '/login'
        })
    } else {
    window.location = '/login'
    }
}
  const deleteWarranty = async (id) => {
    window.confirm("Biztosan törölni szeretnéd a garanciát?") && await axios.delete(apiUrl + `garancia.php?id=${id}`).then((response) => {
      if(response.data == "Record deleted successfully\n"){
        alert("Garancia törölve!")
        fetchData();
      }
    });
  }
  const validateWarranty = async (id) => {
    window.confirm("Biztosan érvényteleníteni szeretnéd a garanciát?") && await axios.patch(apiUrl + `garancia.php?id=${id}`).then((response) => {
      if(response.data == "Record validated successfully\n"){
        alert("Garancia érvénytelenítve!")
        fetchData();
      }
    });
  }
  const getPromoterName = (id) => {
    if (promoters.find(obj => obj.id === id) != undefined) {
      return promoters.find(obj => obj.id === id).username
    } else {
      return id
    }
  }
  const fetchData = async () => {
    try {
      const response = await axios.get(apiUrl + 'garancia.php');
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const ImageModal = ({ images, onClose }) => (
    <div className="image-modal" onClick={closeModal}>
      {images.map((imageUrl, index) => (
        <img key={index} src={imageUrl} className='imageofmodal' alt={`Image ${index + 1}`} onClick={closeModal} />
      ))}
    </div>
  );
  const handleSearch = async () => {
    try {
      const response = await axios.get(apiUrl + `garancia.php?imei=${imeiSearch}`);
      setData(response.data);
    } catch (error) {
      console.error('Error searching data:', error);
    }
  };

  return (
    <div className="container mt-5">
      <h2>Garancialista</h2>
      <div className="mb-3">
        <label htmlFor="imeiSearch">Keresés IMEI alapján:</label>
        {isModalOpen && (
  <ImageModal images={selectedImages} onClick={closeModal} />
)}
        <input
          type="number"
          className="form-control"
          id="imeiSearch"
          value={imeiSearch}
          onChange={(e) => setImeiSearch(e.target.value)}
        />
        <button onClick={handleSearch} className="btn btn-primary mt-2">Keresés</button>
      </div>
      <hr></hr>
      <div className="table-container">
        <table className="data-table">
          <thead>
            <tr>
            <th>Műveletek</th>
              <th>Érvényes</th>
              <th>IMEI</th>
              <th>Email</th>
              <th>Képek</th>
              <th>Dátum</th>
              <th>Termékek</th>
              <th>Garanciák</th>
              <th>Promóter</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id} style={item.isvalid == 0 ? {color:"white", background:"tomato"} : {}}>
                <td><button className='deleteButton delete' onClick={() => deleteWarranty(item.id)}>Törlés</button><br></br><button className='deleteButton validate' onClick={() => validateWarranty(item.id)}>Érvénytelenítés</button></td>
                <td>{item.isvalid == 1 ? "Igen" : "Nem"} {item.isvalid == 0 ? "("+item.validate+"-tól)" : ""}</td>
                <td>{item.imei}</td>
                <td>{item.email}</td>
                <td>
                {item.image1 != "garancia_uploads/" && (
  <p className='imageButton' onClick={() => openModal([apiUrl + item.image1])}>Előlap kép: sarok 1.</p>
)}
{item.image2 != "garancia_uploads/" && (
  <p className='imageButton' onClick={() => openModal([apiUrl + item.image2])}>Előlap kép: sarok 2.</p>
)}
{item.image3 != "garancia_uploads/" && (
  <p className='imageButton' onClick={() => openModal([apiUrl + item.image3])}>Előlap kép: sarok 3.</p>
)}
{item.image4 != "garancia_uploads/" && (
  <p className='imageButton' onClick={() => openModal([apiUrl + item.image4])}>Előlap kép: sarok 4.</p>
)}
{item.image5 != "garancia_uploads/" && (
  <p className='imageButton' onClick={() => openModal([apiUrl + item.image5])}>Hátlap kép: sarok 1.</p>
)}
{item.image6 != "garancia_uploads/" && (
  <p className='imageButton' onClick={() => openModal([apiUrl + item.image6])}>Hátlap kép: sarok 2.</p>
)}
{item.image7 != "garancia_uploads/" && (
  <p className='imageButton' onClick={() => openModal([apiUrl + item.image7])}>Hátlap kép: sarok 3.</p>
)}
{item.image8 != "garancia_uploads/" && (
  <p className='imageButton' onClick={() => openModal([apiUrl + item.image8])}>Hátlap kép: sarok 4.</p>
)}

  <p className='imageButton' onClick={() => openModal([apiUrl + item.image9])}>Készülék IMEI + számla</p>
  <p className='imageButton' onClick={() => openModal([apiUrl + item.image10])}>Garanciális feltételek</p>
</td>

                <td>{item.date}</td>
                <td>{item.products}</td>
                <td>{item.garanciaType}</td>
                <td>{getPromoterName(item.userId)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default List;
