import React, { useState } from 'react';
import './promoter_reg.css';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { apiUrl } from '../App/Constants';

export default function AddPromoter() {
    const [userName, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [realName, setRealName] = useState("");
    const [jobrole, setJobrole] = useState("fulltime");

    const handleSubmit = () => {
        if (localStorage.getItem("isUserLoggedIn")) {
            var decodedUser = jwt_decode(localStorage.getItem("jwt"));
            if (decodedUser.priv === 1) {
                axios.post(apiUrl + 'register.php?username=' + userName + '&password=' + password + '&realname=' + realName + '&role=2' + '&jobrole=' + jobrole )
                alert("Promoter hozzáadva")
            }
      
    } else {
      window.location = '/login'
    }
    }
    return (
        <div className='containerbox'>
            <form onSubmit={handleSubmit}>
                <input
                    className='mobileInput'
                    type="text"
                    placeholder='Felhasználónév'
                    required
                    value={userName}
                    onChange={input => setUsername(input.target.value)}></input>
                <input
                    className='mobileInput'
                    type="text"
                    placeholder='Megjelenítendő név'
                    required
                    value={realName}
                    onChange={input => setRealName(input.target.value)}></input>
                <input
                    className='mobileInput'
                    type="password"
                    placeholder='Jelszó'
                    required
                    value={password}
                    onChange={input => setPassword(input.target.value)}></input>
                <select className='mobileInput' value={jobrole} onChange={e => setJobrole(e.target.value)} required>
            <option value="fulltime">Teljes állás</option>
            <option value="parttime">Részmunkaidős</option>
            <option value="intern">Betanuló</option>
            </select>
                <button
                    type='submit'
                    className='mobileInput submitbutton'>Hozzáadás</button>
            </form>
        </div>
    )
}