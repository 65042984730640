import React, { useState } from "react";
import axios from 'axios';
import { apiUrl } from "../App/Constants";

export default function TypeOfItems() {
    const [itemList, setList] = useState([])
    const [type, setType] = useState("Válaszd ki mit szeretnél módosítani");
    const [locationList, setLocationList] = useState([])
    const [newPhone, setnewPhone] = useState("")
    const [newList, setNewList] = useState([])

    const fetchData = () => {
        fetch(apiUrl + "list.php")
            .then(response => {
                return response.json()
            })
            .then(data => {
                setList(data.itemTypes)
                setLocationList(data.locations)
            })
    }
    const handleType = e => {
        fetchData()
        if (e === "Lokáció") {
            setType("Lokáció")
        } else if (e === "Tok") {
            setType("Tok")
        } else {
            setType("Üvegfólia")
        }
    }
    const handleRemoveFolia = e => {
        if (window.confirm("Biztosan törölni akarod ezt az üvegfólia közül:" + e + "?")) {
            var newItem = JSON.parse(itemList.filter(item => item.itemName === "Üvegfólia")[0].phoneTypes)
            var index = newItem.indexOf(e.target.value);
            if (index > -1) {
                newItem.splice(index, 1);
            }
            setNewList(newItem)
            postNewFolia(newItem)
        }
    }
    const handleRemoveCase = e => {
        if (window.confirm("Biztosan törölni akarod ezt a tok közül:" + e + "?")) {
            var newItem = JSON.parse(itemList.filter(item => item.itemName === "Tok")[0].phoneTypes)
            var index = newItem.indexOf(e.target.value);
            if (index > -1) {
                newItem.splice(index, 1);
            }
            setNewList(newItem)
            postNewTok(newItem)
        }
    }

    const handleAddPhone1 = () => {
        if (window.confirm("Biztosan hozzáadod a " + newPhone + " telefont a tokhoz?")) {
            var newItem = JSON.parse(itemList.filter(item => item.itemName === "Tok")[0].phoneTypes)
            const arr2 = [...newItem, newPhone];
            setNewList(arr2)
            postNewTok(arr2)
        }
    }
    const handleAddPhone2 = () => {
        if (window.confirm("Biztosan hozzáadod a " + newPhone + " telefont az üvegfóliához?")) {
            var newItem = JSON.parse(itemList.filter(item => item.itemName === "Üvegfólia")[0].phoneTypes)
            const arr2 = [...newItem, newPhone];
            setNewList(arr2)
            postNewFolia(arr2)
        }

    }

    const postNewFolia = async e => {
        axios.post(apiUrl + 'list.php?new_value=' + JSON.stringify(e) + '&type=Üvegfólia');
        fetchData();
    }
    const postNewTok = async e => {
        axios.post(apiUrl + 'list.php?new_value=' + JSON.stringify(e) + '&type=Tok');
        fetchData();
    }
    const typeIng = e => {
        if (e === "Tok") {
            return (
                <div>
                    <h2>Tok típusok</h2>
                    <input
                        type="text"
                        placeholder="Új telefontípus neve"
                        value={newPhone}
                        onChange={e => setnewPhone(e.target.value)} />
                    <button type="button" onClick={handleAddPhone1}>Telefon hozzáadása</button>
                    {itemList.filter(i => i.itemName === e).map((val, key) => {
                        return (
                            <p>{JSON.parse(val.phoneTypes).map((vali, key) => {
                                return (
                                    <div className="rowItem"><p>{vali}</p><button className="deleteButton" value={vali} onClick={e => handleRemoveCase(e)}>Törlés</button>
                                    </div>
                                )
                            })}</p>
                        )
                    })}
                </div>
            )
        } else if (e === "Üvegfólia") {
            return (
                <div>
                    <h2>Üvegfólia típusok</h2>
                    <input
                        type="text"
                        placeholder="Új telefontípus neve"
                        value={newPhone}
                        onChange={e => setnewPhone(e.target.value)} />
                    <button type="button" onClick={handleAddPhone2}>Telefon hozzáadása</button>
                    {itemList.filter(i => i.itemName === e).map((val, key) => {
                        return (
                            <p>{JSON.parse(val.phoneTypes).map((vali, key) => {
                                return (
                                    <div className="rowItem"><p>{vali}</p><button className="deleteButton" value={vali} onClick={e => handleRemoveFolia(e)}>Törlés</button>
                                    </div>
                                )
                            })}</p>
                        )
                    })}
                </div>
            )
        }
    }
    return (
        <div>
            <select className='mobileInput' value={type} onChange={e => handleType(e.target.value)} required>
                <option disabled value="Válaszd ki mit szeretnél módosítani">Válaszd ki mit szeretnél módosítani</option>
                <option value="Üvegfólia">Üvegfólia</option>
                <option value="Tok">Tok</option>
            </select>

            {typeIng(type)}

        </div>
    )
}