import React, { useEffect, useState, useContext } from 'react';
import { ThemeContext } from './ThemeContext';
import '../Leltar/Leltar.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import { faCalendar, faCashRegister } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { apiUrl } from './Constants';

export default function Service() {
  const { theme } = useContext(ThemeContext);
  const primaryColor = theme.primary;
  const [items, setItems] = useState([])
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [summary, setSummary] = useState({
    serviceCounts: {},
    totalPurchasePrice: 0,
    totalIncome: 0,
    totalProfit: 0,
    paymentCounts: {}
  });

  const fetchData = (time, endTime) => {
    
    if (localStorage.getItem("isUserLoggedIn") === "true") {
    var options = {  
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem("jwt")
      }
    }
    var url = apiUrl + "service.php?date=" + time + "&endDate=" + endTime

      fetch(url, options)
        .then(response => {
          return response.json()
        })
        .then(data => {
            let serviceCounts = {};
    let totalPurchasePrice = 0;
    let totalIncome = 0;
    let totalProfit = 0;
    let paymentCounts = {};
    var totalWarranty = 0;
    data.forEach(item => {
      if (serviceCounts[item.repair_type]) {
        serviceCounts[item.repair_type] += 1;
      } else {
        serviceCounts[item.repair_type] = 1;
      }
      if(item.repair_type.includes("Gara")){
        totalWarranty += parseInt(item.purchase_price);
      }
      // Árak és különbözet összesítése
      console.log(item)
      totalPurchasePrice += parseInt(item.purchase_price);
      totalIncome += parseInt(item.income);
      totalProfit += parseInt(item.income) - parseInt(item.purchase_price);
      
      // Fizetési típus összesítése
      if (paymentCounts[item.payment_type]) {
        paymentCounts[item.payment_type] += 1;
      } else {
        paymentCounts[item.payment_type] = 1;
      }
    });

    setSummary({
      serviceCounts,
      totalPurchasePrice,
      totalIncome,
      totalProfit,
      paymentCounts
    });
          setItems(data)
          console.log(totalWarranty);
        })
        .catch(error => {
         window.location = '/login'
        })
      
    } else {
      window.location = '/login'
    }
    
    
  }
  useEffect(() => {
    
    fetchData(format(startDate, "yyyy-MM-dd"), format(endDate, "yyyy-MM-dd"));
    const intervalCall = setInterval(() => {
      fetchData(format(startDate, "yyyy-MM-dd"), format(endDate, "yyyy-MM-dd"));
    }, 30000);
    
    return () => {
      clearInterval(intervalCall);
    };
    
  }, [startDate, endDate]);

  const setDataAndFetch = date => {
    setStartDate(date)
    if (date > endDate) {
        setEndDate(date)
    }
    fetchData(format(date, "yyyy-MM-dd"), format(endDate, "yyyy-MM-dd"))
  }
  const setEndDataAndFetch = date => {
    setEndDate(date)
    if (date < startDate) {
        setStartDate(date)
    }
    fetchData(format(startDate, "yyyy-MM-dd"), format(date, "yyyy-MM-dd"))
  }
  const getTypeString = (type) => {
    switch (type) {
        case "cash":
            return "Készpénz";
        case "card":
            return "Kártya";
        case "creditcard":
            return "Bankkártya";
        default:
            break;
    }
  }
  const formatPrice = (value) => {
    // Convert the plain number to a numeric value
    const numericValue = parseFloat(value);
    // Check if the numeric value is a valid number
    if (!isNaN(numericValue)) {
      // Use toLocaleString to format the number with spaces as thousand separators
      return numericValue.toLocaleString('hu');
    } else {
      // Return the original value if it's not a valid number
      return value;
    }
  };
  const Summary = () => {
    return (
      <div style={{
        backgroundColor: '#f9f9f9',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        margin: '20px 0'
        , paddingBottom: "10px"
      }}>
        <h3 style={{ color: primaryColor, textAlign: 'center', marginBottom: '20px', paddingTop: "10px" }}>Összegzés</h3>
        <div style={{ marginBottom: '20px' }}>
          {Object.keys(summary.serviceCounts).map((key, index) => (
            <p key={index} style={{ margin: '5px 0', fontSize: '16px' }}>
              <strong>{key}:</strong> {summary.serviceCounts[key]} db
            </p>
          ))}
        </div>
        <div style={{ marginBottom: '20px' }}>
          <p style={{ margin: '5px 0', fontSize: '16px' }}><strong>Összes beszerzési ár:</strong> {formatPrice(parseInt(summary.totalPurchasePrice))} Ft</p>
          <p style={{ margin: '5px 0', fontSize: '16px' }}><strong>Összes eladási ár:</strong> {formatPrice(parseInt(summary.totalIncome))} Ft</p>
          <p style={{ margin: '5px 0', fontSize: '16px' }}><strong>Összes különbözet:</strong> {formatPrice(parseInt(summary.totalProfit))} Ft</p>
        </div>
        <div>
          {Object.keys(summary.paymentCounts).map((key, index) => (
            <p key={index} style={{ margin: '5px 0', fontSize: '16px' }}>
              <strong>{getTypeString(key)} fizetések:</strong> {summary.paymentCounts[key]} db
            </p>
          ))}
        </div>
      </div>
    );
  };
  
  const ItemsOfService = () => {
    if (items.length < 400) {
      return (
          <div className='productTable' >
          {items.map((val, key) => {
          return (
            <div key={key} className="productRow" style={{ borderColor: primaryColor, borderWidth: 3 }}>
              <div className='centerDiv'>
                <div className='halfdiv'>
                <div className='location' style={{ backgroundColor: primaryColor}}><div className='locationIcon' style={{ backgroundColor: 'white'}}><FontAwesomeIcon style={{ color: primaryColor, fontSize: "11px", padding: "5px", paddingLeft: "6px"}} icon={faCashRegister} /></div><div className='locationName'>{getTypeString(val.payment_type)}</div></div>
                </div>
                <div className='halfdiv timediv'>
                <div className='timetext' style={{ backgroundColor: "white", color:"black"}}><div className='clockIcon' style={{ backgroundColor: primaryColor}}><FontAwesomeIcon style={{ color: "white", fontSize: "11px", padding: "5px", paddingLeft: "5px"}} icon={faCalendar} /></div><div className='timeName'>{val.date}</div></div>
                  <p className='timetable'></p>
                </div>
                <hr style={{ color: primaryColor, margin: "5px"}}></hr>
                <p style={{fontSize: "14px", padding: "5px", borderRadius: "10px", border: "1px solid grey", marginBottom: "5px"}}>Beszerzési ár: {formatPrice(parseInt(val.purchase_price))} Ft</p>
                <p style={{fontSize: "14px", padding: "5px", borderRadius: "10px", border: "1px solid grey", marginBottom: "5px"}}>Eladási ár: {formatPrice(parseInt(val.income))} Ft</p>
                <p style={{fontSize: "14px", padding: "5px", borderRadius: "10px", border: "1px solid grey", marginBottom: "5px"}}>Különbözet: {formatPrice(parseInt(val.income)-parseInt(val.purchase_price))} Ft</p>
              </div>
              <p style={{fontSize: "14px", fontWeight: "bold", marginTop: "15px"}}>{val.repair_type} (#{val.service_id})</p>
              <center><hr style={{ color: primaryColor, margin: "5px", width: "20px"}}></hr></center>
              
            </div>
            
          )
        })}
            </div>
              )
    }
    
  }
  return(
    <div>
      <br />
      <h3 style={{color: primaryColor}}>Szerviz</h3>
      <p className='inputLabel'>Kezdődátum</p>
          <DatePicker classname="datepicker" selected={startDate} onChange={(date) => setDataAndFetch(date)} />
          <p className='inputLabel'>Végedátum</p>
          <DatePicker classname="datepicker" selected={endDate} onChange={(date) => setEndDataAndFetch(date)} />
            {Summary()}
            <h3 style={{ color: primaryColor, textAlign: 'center', marginBottom: '20px', paddingTop: "10px" }}>Szervizek</h3>
            {ItemsOfService()}
    </div>
  );
}
