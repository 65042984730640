import './App.css';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Navbar from './Components/App/Navbar';
import Login from "./Components/Login/Login";
import Leltar from "./Components/Leltar/Leltar";
import NewItem from "./Components/New_item/New_item";
import ReplaceItem from "./Components/New_item/replace_item";
import React from 'react';
import Timeheet from './Components/TimeSheet/Timesheet';
import SettingsPage from './Components/App/Settings';
import { ThemeProvider } from './Components/App/ThemeContext';
import ProfilePage from './Components/Profile/Profile';
import GuestProfile from './Components/Profile/GuestProfile';
import Masters from './Components/Masters/Masters';
import Elszamolas from './Components/Elszamolas/Elszamolas';
import Raktarkeszlet from './Components/Raktarkeszlet/Raktarkeszlet';
import Buntetesek from './Components/Buntetes/Buntetes';
import Garancia from './Components/Garancia/Garancia';
import Voucher from './Components/Voucher/Voucher';
import CardVoucher from './Components/Voucher/CardVoucher';
import WeeklyMasters from './Components/Masters/WeeklyMasters';
import WeeklyTimesheet from './Components/TimeSheet/WeeklyTimesheet';
import Intern from './Components/Masters/Intern';
import Service from './Components/App/Service';
import ChartComponent from './Components/Leltar/AiStat';
import FinancialSummary from './Components/App/Penzugy';
import FinancialWebshopSummary from './Components/App/PenzugyWebshop';
import Teams from './Components/Masters/Team';
import CouponGenerator from './Components/App/CouponGenerate';

function App() {
  return (
    <ThemeProvider>
    <div className="App">
      
      <header className="App-header">
        <BrowserRouter>
          <Navbar />
        <div className="container">
            <Routes>
              <Route path="/" element={<Leltar />} />
              <Route path="/login" element={<Login />} />
              <Route path="/leltar" element={<Leltar />} />
              <Route path="/garancia" element={<Garancia />} />
              <Route path="/new" element={<NewItem />} />
              <Route path="/replace" element={<ReplaceItem />} />
              <Route path="/settings" element={<SettingsPage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/guestprofile/:id" element={<GuestProfile />} />
              <Route path="/masters" element={<Masters />} />
              <Route path="/teams" element={<Teams />} />
              <Route path="/interns" element={<Intern />} />
              <Route path="/weeklymasters" element={<WeeklyMasters />} />
              <Route path="/elszamolas" element={<Elszamolas />} />
              <Route path="/idobeosztas" element={<Timeheet />} />
              <Route path="/weeklytimesheet" element={<WeeklyTimesheet />} />
              <Route path="/raktarkeszlet" element={<Raktarkeszlet />} />
              <Route path="/buntetesek" element={<Buntetesek />}/>
              <Route path="/voucher" element={<Voucher />} />
              <Route path="/card" element={<CardVoucher />} />
              <Route path="/szerviz" element={<Service />} />
              <Route path="/chart" element={<ChartComponent/>}/>
              <Route path="/penzugyi-jelentes" element={<FinancialSummary/>}/>
              <Route path="/webshop-penzugyi-jelentes" element={<FinancialWebshopSummary/>}/>
              <Route path="/tombola-generalas" element={<CouponGenerator/>}/>
        </Routes>
        </div>
      </BrowserRouter>
      </header>
    </div>
    </ThemeProvider>
  );
}

export default App;
