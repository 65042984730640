import React, { useState, useEffect, useContext } from 'react';
import './Navbar.css';
import {
  Nav,
  NavLink
} from './NavbarElements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillTrendUp, faBars, faPlus, faRightFromBracket, faDatabase, faRemove, faClock, faWrench, faScrewdriverWrench, faPerson, faCrown, faMoneyBill, faStoreSlash, faMoneyBillTransfer, faBook, faCheckCircle, faBarsStaggered, faIdCardAlt, faUserGraduate, faPersonShelter } from '@fortawesome/free-solid-svg-icons';
import jwt_decode from "jwt-decode";
import { ThemeContext } from '../App/ThemeContext';
import { apiUrl } from '../App/Constants';
import { faGift } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  const [isToggleOn, setIsToggleOn] = useState(false);
  const { theme } = useContext(ThemeContext);
  const primaryColor = theme.primary;

  const handleLogout = () => {
    localStorage.setItem("jwt", "");
    localStorage.setItem("isUserLoggedIn", false);
    window.location = '/login'
  }

  const handleClick = () => {
    setIsToggleOn(!isToggleOn);
  }
  
  const handleClose = () => {
    setIsToggleOn(false);
  }

  if (localStorage.getItem("isUserLoggedIn") === "true") {
    const decodedUser = jwt_decode(localStorage.getItem("jwt"));

    return (
      <>
        <button className='navbar' onClick={handleClick}><FontAwesomeIcon className='faicon' icon={faBars} /></button>
        {isToggleOn && (
          <div className='background' onClick={handleClose}>
            <div className='fullscreen'>
            <div className='headerImage' style={{position: "fixed", width: "200px;", backgroundColor: primaryColor}}>
                <img style={{ width: "80px", marginTop: "10px" }} src={apiUrl + 'slimlogo.png'} />
              </div>
              <Nav className='navMenu'>
                <NavLink style={{ paddingTop: "100px" }} className='menuItem' to='/leltar' activeStyle onClick={handleClick}>
                  <FontAwesomeIcon className='famenu menuicon' icon={faDatabase} />Leltár
                </NavLink>
                <hr style={{ margin: "2px", height: "1px", background: "lightgrey", width: "100px", marginLeft: "15px" }} />
                <NavLink className='menuItem' to='/new' activeStyle onClick={handleClick}>
                  <FontAwesomeIcon className='famenu menuicon' icon={faPlus} />Új eladás
                </NavLink>
                <hr style={{ margin: "2px", height: "1px", background: "lightgrey", width: "100px", marginLeft: "15px" }} />
                <NavLink className='menuItem' to='/garancia' activeStyle onClick={handleClick}>
                  <FontAwesomeIcon className='famenu menuicon' icon={faCheckCircle} />Garancia
                </NavLink>
                <hr style={{ margin: "2px", height: "1px", background: "lightgrey", width: "100px", marginLeft: "15px" }} />
                <NavLink className='menuItem' to='/replace' activeStyle onClick={handleClick}>
                  <FontAwesomeIcon className='famenu menuicon' icon={faRemove} />Új csere/selejt/próba
                </NavLink>
                <hr style={{ margin: "2px", height: "1px", background: "lightgrey", width: "100px", marginLeft: "15px" }} />
                <NavLink className='menuItem' to='/card' activeStyle onClick={handleClick}>
                  <FontAwesomeIcon className='famenu menuicon' icon={faIdCardAlt} />Kártya beváltás
                </NavLink>
                <hr style={{ margin: "2px", height: "1px", background: "lightgrey", width: "100px", marginLeft: "15px" }} />
                {(decodedUser.jobrole !== "intern" || decodedUser.priv === 1 || decodedUser.priv === 0) && (<NavLink className='menuItem' to='/masters' activeStyle onClick={handleClick}>
                      <FontAwesomeIcon className='famenu menuicon' icon={faCrown} />Masters
                  </NavLink>)}
                  <hr style={{ margin: "2px", height: "1px", background: "lightgrey", width: "100px", marginLeft: "15px" }} />
                  <NavLink className='menuItem' to='/teams' activeStyle onClick={handleClick}>
                      <FontAwesomeIcon className='famenu menuicon' icon={faPersonShelter} />Csapatom
                  </NavLink>
<hr style={{ margin: "2px", height: "1px", background: "lightgrey", width: "100px", marginLeft: "15px" }} />
{(decodedUser.jobrole === "intern" || decodedUser.priv === 1 || decodedUser.priv === 0) && (
  <NavLink className='menuItem' to='/interns' activeStyle onClick={handleClick}>
    <FontAwesomeIcon className='famenu menuicon' icon={faUserGraduate} />Betanulók
  </NavLink>
)}
<hr style={{ margin: "2px", height: "1px", background: "lightgrey", width: "100px", marginLeft: "15px" }} />
<NavLink  className='menuItem' to='/idobeosztas' activeStyle onClick={handleClick}>
  <FontAwesomeIcon className='famenu menuicon' icon={faClock} />Időbeosztás
</NavLink>
{( decodedUser.priv === 1 ||  decodedUser.priv === 0) && (<hr style={{ margin: "2px", height: "1px", background: "lightgrey", width: "100px", marginLeft: "15px" }} />)}
{(decodedUser.priv === 1 ||  decodedUser.priv === 0) && (
  <NavLink className='menuItem' to='/settings' activeStyle onClick={handleClick}>
    <FontAwesomeIcon className='famenu menuicon' icon={faWrench} />Beállítások
  </NavLink>
)}
{( decodedUser.priv === 1 ||  decodedUser.priv === 0) && (<hr style={{ margin: "2px", height: "1px", background: "lightgrey", width: "100px", marginLeft: "15px" }} />)}
{( decodedUser.priv === 1 ||   decodedUser.priv === 0) && (
  <NavLink className='menuItem' to='/raktarkeszlet' activeStyle onClick={handleClick}>
    <FontAwesomeIcon className='famenu menuicon' icon={faMoneyBillTransfer} />Raktárkészlet
  </NavLink>
)}
{(( decodedUser.priv === 1) || ( decodedUser.priv === 0)) && (<hr style={{ margin: "2px", height: "1px", background: "lightgrey", width: "100px", marginLeft: "15px" }} />)}
{( decodedUser.priv === 1) || ( decodedUser.priv === 0) && (
  <NavLink className='menuItem' to='/szerviz' activeStyle onClick={handleClick}>
    <FontAwesomeIcon className='famenu menuicon' icon={faScrewdriverWrench} />Szerviz
  </NavLink>
)}
{( decodedUser.priv === 0) && (<hr style={{ margin: "2px", height: "1px", background: "lightgrey", width: "100px", marginLeft: "15px" }} />)}
{( decodedUser.priv === 0) && (
  <NavLink className='menuItem' to='/penzugyi-jelentes' activeStyle onClick={handleClick}>
    <FontAwesomeIcon className='famenu menuicon' icon={faMoneyBillTrendUp} />Pénzügyi jelentés
  </NavLink>
)}
{( decodedUser.priv === 0) && (<hr style={{ margin: "2px", height: "1px", background: "lightgrey", width: "100px", marginLeft: "15px" }} />)}
{( decodedUser.priv === 0) && (
  <NavLink className='menuItem' to='/tombola-generalas' activeStyle onClick={handleClick}>
    <FontAwesomeIcon className='famenu menuicon' icon={faGift} />Tombola generálás
  </NavLink>
)}
{( decodedUser.priv === 0) && (<hr style={{ margin: "2px", height: "1px", background: "lightgrey", width: "100px", marginLeft: "15px" }} />)}
{( decodedUser.priv === 0) && (
  <NavLink className='menuItem' to='/webshop-penzugyi-jelentes' activeStyle onClick={handleClick}>
    <FontAwesomeIcon className='famenu menuicon' icon={faMoneyBillTrendUp} />Webshop pénzügyi jelentés
  </NavLink>
)}
<hr style={{ margin: "2px", height: "1px", background: "lightgrey", width: "100px", marginLeft: "15px" }} />
<NavLink className='menuItem' to='/elszamolas' activeStyle onClick={handleClick}>
  <FontAwesomeIcon className='famenu menuicon' icon={faMoneyBill} />Elszámolás
</NavLink>
<hr style={{ margin: "2px", height: "1px", background: "lightgrey", width: "100px", marginLeft: "15px" }} />
<NavLink className='menuItem' to='/buntetesek' activeStyle onClick={handleClick}>
  <FontAwesomeIcon className='famenu menuicon' icon={faStoreSlash} />Büntetések
</NavLink>
<hr style={{ margin: "2px", height: "1px", background: "lightgrey", width: "100px", marginLeft: "15px" }} />
<NavLink className='menuItem' to='/profile' activeStyle onClick={handleClick}>
  <FontAwesomeIcon className='famenu menuicon' icon={faPerson} />Profil
</NavLink>
<hr style={{ margin: "2px", height: "1px", background: "lightgrey", width: "100px", marginLeft: "15px" }} />
<NavLink style={{ paddingBottom: "100px" }} className='menuItem logout' to='/logout' activeStyle onClick={handleLogout}>
  <FontAwesomeIcon className='famenu menuicon' icon={faRightFromBracket} />Kijelentkezés
</NavLink>
              </Nav>
            </div>
          </div>
        )}
        {!isToggleOn && (<div className='mobile-navbar'>
          <Nav className='navMenu1 '>
            <NavLink className='menuItem1 bottomnavbarmenuitem' to='/leltar' activeStyle>
              <FontAwesomeIcon className='famenu faiconmenu' icon={faDatabase} /><br></br>Leltár
            </NavLink>
            <NavLink className='menuItem1 bottomnavbarmenuitem' to='/new' activeStyle>
              <FontAwesomeIcon className='famenu faiconmenu' icon={faPlus} /><br></br>Eladás
            </NavLink>
            <NavLink className='menuItem1 bottomnavbarmenuitem' to='/garancia' activeStyle>
              <FontAwesomeIcon className='famenu faiconmenu' icon={faCheckCircle} /><br></br>Garancia
            </NavLink>
            <NavLink className='menuItem1 bottomnavbarmenuitem' to='/replace' activeStyle>
              <FontAwesomeIcon className='famenu faiconmenu' icon={faRemove} /><br></br>Csere/selejt
            </NavLink>
            <p className='menuItem1 bottomnavbarmenuitem' onClick={handleClick}><FontAwesomeIcon className='famenu faiconmenu' icon={faBarsStaggered} /><br></br>Több</p>
          </Nav>
        </div>)}
      </>)} else {
    return (
      <div>
       
      </div>
    )}}

    export default Navbar;