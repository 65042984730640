import React from 'react';
import './Badges.css';

const GoldBadge = ({ text, date }) => (
    <div class="quiz-medal">
      <div class="quiz-medal__circle quiz-medal__circle--gold">
        <div class='textdiv'><span className="text">{text}</span>
      <br />
      <span className="date">{date}</span></div>
      
      </div>
    </div>
);

const SilverBadge = ({ text, date }) => (
    <div class="quiz-medal">
    <div class="quiz-medal__circle quiz-medal__circle--silver">
      <div class='textdiv'><span className="text">{text}</span>
    <br />
    <span className="date">{date}</span></div>
    
    </div>
  </div>
);

const BronzeBadge = ({ text, date }) => (
    <div class="quiz-medal">
    <div class="quiz-medal__circle quiz-medal__circle--bronze">
      <div class='textdiv'><span className="text">{text}</span>
    <br />
    <span className="date">{date}</span></div>
    
    </div>
  </div>
);

const getBadges = (foundUser) => {
    if (foundUser == null) {
      return;
    } else {
      if (foundUser.bonuses == null) {
        return;
      } else {
        return foundUser.bonuses.map((bonus) => {
            if (bonus["bonustitle"] == null) {
              return;
            }
            if (bonus.bonustitle.includes(" I.")){
                return GoldBadge({ text: bonus.bonustitle, date: bonus.yearmonth + " (" + bonus.weeknum + ". hét)"})
            } else if (bonus.bonustitle.includes(" II.")){
                return SilverBadge({ text: bonus.bonustitle, date: bonus.yearmonth + " (" + bonus.weeknum + ". hét)" })
            } else if (bonus.bonustitle.includes(" III.")){
                return BronzeBadge({ text: bonus.bonustitle, date: bonus.yearmonth + " (" + bonus.weeknum + ". hét)" })
            } else if (bonus.bonustitle.includes("Szülinapi bónusz")){
                return
            } else {
                return GoldBadge({ text: bonus.bonustitle, date: bonus.yearmonth })
            }
        })
      }
    }
  }
export { getBadges };
