// GuestProfile.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import placeholderImage from '../../img/slimstore_logo.png'
import { getBadges } from '../App/Badges';
import { apiUrl } from '../App/Constants';

function GuestProfile() {
    const [error, setError] = useState('');
    const [profile, setUserProfile] = useState([]);
    const [profilePicture, setProfilePicture] = useState("");
    let { id } = useParams();

    useEffect(() => {
        fetchProfile()
      }, []);

    const fetchProfile = () => {
        if (localStorage.getItem("isUserLoggedIn") === "true") {
        var options = {  
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem("jwt")
          }
        }
        var url = apiUrl + "profile.php?userId=" + id
          fetch(url, options)
            .then(response => {
              return response.json()
            })
            .then(data => {
                setProfilePicture(data[0]["profile_pic"])
                setUserProfile(data)
            })
            .catch(error => {
             window.location = '/login'
            })
          
        } else {
          window.location = '/login'
        }
        
        
      }
      const getJobName = (foundUser) => {
        switch (foundUser.jobrole) {
          case "fulltime":
            return "Teljes munkaidős"
          case "parttime":
            return "Részmunkaidős"
          case "intern":
            return "Betanuló" + " - " + internPhase(foundUser) + ". fázis"
          default:
            return "Inaktív"
        }
      }
      const internPhase = (foundUser) => {
        if(foundUser == null){
          return;
        } else {
        
        const today = new Date();
        const date = new Date(foundUser.created_at);
        const timeDifference = today - date;
        const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
        if (daysDifference < 10) {
        return 1;
      } else if (daysDifference >= 10 && daysDifference < 20) {
        return 2;
      } else {
        return 3;
      }
    }
}
    return (
      <div style={{ textAlign: 'center' }}>
        <h2>{profile.length > 0 ? profile[0]["real_name"] : ""}</h2>
        <p className='jobnametitle'>{profile.length > 0 ? getJobName(profile[0]) : ""}</p>
        
        <hr class="hrc"></hr>
        <div
          style={{
            width: '300px',
            height: '300px',
            borderRadius: '100%',
            border: '3px solid #ddd',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            margin: 'auto',
            backgroundImage: `url(${profilePicture == "" ? placeholderImage : apiUrl + "profile_images/" + profilePicture})`,
          }}
        ></div>
      {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
      <hr class="hrc"></hr>
      {profile[0] != null && profile[0].bonuses.length > 0 && <h3>Eredmények</h3>}
      
      <div className='medals'>{profile[0] != null ? getBadges(profile[0]) : null}</div>
    </div>
    );
  }

export default GuestProfile;