import React, { useState, useEffect } from 'react';
import './promoter_reg.css';
import { apiUrl } from '../App/Constants';

export default function RemovePromoter() {
    const [items, setItems] = useState([])

    const fetchData = () => {
        if (localStorage.getItem("isUserLoggedIn")) {
            setItems([])
        var options = {  
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem("jwt")
        }
        }
        var url = apiUrl + "promoter.php"
        fetch(url, options)
            .then(response => {
            return response.json()
            })
            .then(data => {
            setItems(data.filter(item => item.isActive === "1"))
            })
            .catch(error => {
                window.location = '/login'
            })
        } else {
        window.location = '/login'
        }
    }

    const handleRemove = user => {
        if (window.confirm("Biztosan eltávolítod a kiválaszott promotert?")) {
            if (localStorage.getItem("isUserLoggedIn")) {
            var options = {  
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("jwt")
            }
            }
        var url = apiUrl + "promoter.php?removeID=" + user.target.value
                fetch(url, options)
                fetchData()
        } else {
        window.location = '/login'
        }
        };
    }


    useEffect(() => {
        fetchData();
    }, []);
    return (
        <div>
            {items.filter(item => item.isActive === "1").map((val, key) => {
                return (<div key={key}><p className='user'>{val.username} - {val.real_name}</p><button className='user buttonu' onClick={input => handleRemove(input)} value={val.id}>Eltávolítás</button></div>)
                
            })}
        </div>
    )
}