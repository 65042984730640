import React, { useState, useContext } from 'react';
import axios from 'axios';
import './Login.css';
import logo from '../../img/slimstore_logo.png'
import packageJson from '../../../package.json';
import { ThemeContext } from '../App/ThemeContext';
import { apiUrl } from '../App/Constants';

export default function Login() {
  const { theme } = useContext(ThemeContext);
  const primaryColor = theme.primary;
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const handleLogin = async e => {
    localStorage.setItem("jwt", e.data.jwt)
    localStorage.setItem("isUserLoggedIn", true);
    window.location = '/leltar'
 }
 const divStyle = {
  /* További stílusok */
  height: '50%',
  marginBottom: '100px',
};
  const handleSubmit = async e => {
    e.preventDefault();
        axios.post(apiUrl + 'auth.php?username='+ username +'&password=' + password).then(response => handleLogin(response))
          .catch(error => {
            localStorage.setItem("jwt", "");
            alert("Sikertelen bejelentkezés! :(");
          return;
        });
    }

  return(

    <div style={{height: "100vh"}}>

      <div style={divStyle} className='backgroundImage wave-container'><img src={logo} className='logo'></img></div>
      <form onSubmit={handleSubmit}>
      <input
        className='mobileInput'
        placeholder='felhasználónév'
        type="text"
        value={username}
        onChange={e => setUserName(e.target.value)}
      />
      <input
        className='mobileInput'
        placeholder='jelszó'
        type="password"
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <button className='mobileInput submitbutton' type="submit" style={{backgroundColor: primaryColor}}>bejelentkezés</button>
      <p className='version' style={{backgroundColor: primaryColor}}>v{packageJson.version}</p>
    </form>
    </div>

  )

}

