import React, { useState } from 'react';
import { Scanner } from '@yudiel/react-qr-scanner';
import axios from 'axios';
import { apiUrl } from '../App/Constants';

const Test = (props) => {
  const [data, setData] = useState('');

  const handleActivate = async () => {
    try {
        // Első lépés: Ellenőrzés, hogy már aktiválták-e
        const isActivatedResponse = await axios.get(apiUrl + 'webshopvoucher.php?qrstring=' + data);
        const isActivatedData = isActivatedResponse.data;
        if (isActivatedData[0].isUsed === '0') {
            if(window.confirm("Biztosan aktiválni szeretnéd a "+ data + " sorszámú vásárlást? (Nincs lehetőség a visszavonásra)")){
              const activationResponse = await axios.post(apiUrl + 'webshopvoucher.php?qrstring=' + data);
              const activationData = activationResponse.data;
              if (activationData == "Sikeresen felhasználva!") {
                window.alert(`Sikeresen aktiválva: ${data}`);
                window.location = '/voucher'
                
              } else { 
                window.alert(`Az aktiválás sikertelen!`);
                window.location = '/voucher'
              }
            }
        } else {
          window.alert('Ez a vásárlás már aktiválva van.');
        }
      } catch (error) {
        console.error('Hiba történt:', error.message);
        window.alert('Valami hiba történt az aktiválás során.');
        window.location = '/voucher'
      }
    
  };

  const handleChange = (event) => {
    setData(event.target.value);
  }
  return (
    <>
    <h1>Webshopos fólia rendelés aktiválása</h1>
      <Scanner
      legacyMode={true}
      onScan={(result) => setData(result[0].rawValue)}
      onError={(error) => window.alert("Hibás QR kód")}
      />
      <div style={{ marginTop: '25px', marginBottom: '100px' }}> {/* Hozzáadott hely */}
        <input type='text' value={data} onChange={handleChange}></input>
        <button onClick={handleActivate} style={{ marginLeft: '10px' }}>Aktiválás</button> {/* Aktiváló gomb */}
      </div>
    </>
  );
};

export default Test;