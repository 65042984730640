import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import '../../App.css';
import { apiUrl } from './Constants';

var categorizedCosts = {
  "Fizetések": {
  "Csereklei Dávid": 2260000,
  "Bodnár Richárd": 2260000,
  "László Emma": 340000,
  "Zentai Zsófia": 340000,
  "Nagy Alexandra": 960000,
  "Fehér Gábor": 1040000,
  "Kiss Marcell": 1110000,
  "Kövy Máté": 850000,
  "Léda Kitti": 360000,
  "Keveházi Lili": 150000,
  "Wágner Péter": 100000,},
  "Bérlemények": {
  "Iroda": 950000,
  "Andrássy": 2300000,
  "Westend": 1300000,
  "Westend II.": 1600000,
  "Westend III": 1300000,
  "Pólus Center": 500000},
  "Irodai Kiadások": {
    "Rózsa utca - garázs": 80000,
"Profession": 440000,
  "Telefon/internet": 220000,
  "Könyvelő": 300000,
  "Fiat 500": 190000,
  "Fix bónuszok": 430000,
  "Turcsik": 600000,
  "Takaritas": 140000,
  },
};
var categorizedCostsAfterSept = {
  "Fizetések": {
  "Csereklei Dávid": 2560000,
  "Bodnár Richárd": 2560000,
  "Zentai Zsófia": 565000,
  "Nagy Alexandra": 960500,
  "Fehér Gábor": 1130000,
  "Kövy Máté": 850000,
  "Farkas Attila": 847500,
  "Léda Kitti": 360000,
  "Keveházi Lili": 150000,
  "Wágner Péter": 100000,},
  "Bérlemények": {
  "Iroda": 950000,
  "Andrássy": 2300000,
  "Westend": 1300000,
  "Westend II.": 1600000,
  "Westend III": 1300000,
  "Pólus Center": 500000},
  "Irodai Kiadások": {
    "Rózsa utca - garázs": 80000,
"Profession": 440000,
  "Telefon/internet": 220000,
  "Könyvelő": 300000,
  "Fiat 500": 190000,
  "Fix bónuszok": 430000,
  "Turcsik": 600000,
  "Takaritas": 140000,
  },
};
const itemCosts = {
  "Slim Case": 700,
  "Slim Pro Case": 1000,
  "Slim Pro Magsafe Case": 1400,
  "Slim UV": 700,
  "Slim Privacy Matt": 700,
  "Slim Self-healing": 400,
  "Slim HD": 200,
  "Slim Pad HD": 500,
  "Slim Pad Matt": 500,
  "Slim Power": 2000
};

const itemMapping = {
  "Slim Self-healing": ["Self-healing fólia", "Slim Self-healing"],
  "Slim UV": ["UV fólia", "Slim UV"],
  "Slim Privacy Matt": ["Slim Privacy Matt", "Privacy Matt", "Privacy fólia", "Matt fólia"],
  "Slim Pro Case": ["Pro case", "Slim Pro Case"],
  "Slim Pro Magsafe Case": ["Magsafe case", "Slim Pro Magsafe Case"],
  "Slim Case": ["Fekete tok", "Slim Case", "Tok", "Slim Case Garanciális"],
  "Slim Pad Matt": ["iPad matt fólia", "Matt tablet fólia", "Slim Pad Matt"],
  "Slim Pad HD": ["Hd tablet fólia", "Slim Pad HD", "Hd tablet fólia", "iPad HD fólia"],
  "Slim HD": ["Hd (sarga)", "Sima fólia", "Slim HD", "Slim HD Garanciális"],
  "Slim Power": ["Powerbank"]
};

const FinancialSummary = () => {
  const today = new Date();
  const [selectedMonth, setSelectedMonth] = useState(today);
  const [selectedDate, setSelectedDate] = useState(today);
  const [viewType, setViewType] = useState('daily');
  const [data, setData] = useState([]);
  const [replaceData, setReplaceData] = useState([]);
  const [dataQty, setDataQty] = useState([]);
  const [error, setError] = useState(null);
  const [promoters, setAllPromoters] = useState([]);
  const [promoterPayments, setPromoterPayments] = useState([]);
  const [newCostName, setNewCostName] = useState('');
  const [newCostValue, setNewCostValue] = useState('');
  const [additionalCosts, setAdditionalCosts] = useState([]);
  const [openCategories, setOpenCategories] = useState({});
  const [isOpenedOther, setIsOpenedOther] = useState(false);
  const [selectedCost, setSelectedCost] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [editedCost, setEditedCost] = useState({ oldItemName: '', oldItemPrice: '', date: '', newItemName: '', newItemPrice: '' });

  const handleRowClick = (cost) => {
    setSelectedCost(cost);
    setEditedCost({
      oldItemName: cost.itemName,
      oldItemPrice: cost.itemPrice,
      date: cost.date,
      newItemName: cost.itemName,  // Alapból az eredeti értékeket adjuk meg
      newItemPrice: cost.itemPrice
    });
    setIsPopupOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedCost({ ...editedCost, [name]: value });
  };

  const handleSave = () => {
    // Frissítjük az adatbázist és a táblázatot
    updateCost(editedCost);
    setIsPopupOpen(false);
  };

  const updateCost = (cost) => {
    fetch( apiUrl + `finance.php`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        oldItemName: cost.oldItemName,
        oldItemPrice: cost.oldItemPrice.toString(),
        date: cost.date,
        newItemName: cost.newItemName,
        newItemPrice: cost.newItemPrice.toString(),
      }),
    })
      .then(data => {
        const updatedCosts = additionalCosts.map(cost => {
          if (cost.itemName === editedCost.oldItemName && 
              cost.itemPrice === editedCost.oldItemPrice && 
              cost.date === editedCost.date) {
            return {
              ...cost,
              itemName: editedCost.newItemName,
              itemPrice: parseInt(editedCost.newItemPrice),
            };
          }
          return cost;
        });
        // Frissítjük a `additionalCosts` állapotot
        setAdditionalCosts(updatedCosts);
      })
      .catch(error => console.error('Error updating cost:', error));
  };
  const toggleCategory = (category) => {
    setOpenCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };
  const handleMonthChange = (date) => {
    if(date.getFullYear() === 2024 && date.getMonth()+1 >= 9){
      categorizedCosts = categorizedCostsAfterSept;
    } else {
      categorizedCosts = {
        "Fizetések": {
        "Csereklei Dávid": 2260000,
        "Bodnár Richárd": 2260000,
        "László Emma": 340000,
        "Zentai Zsófia": 340000,
        "Nagy Alexandra": 960000,
        "Fehér Gábor": 1040000,
        "Kiss Marcell": 1110000,
        "Kövy Máté": 850000,
        "Léda Kitti": 360000,
        "Keveházi Lili": 150000,
        "Wágner Péter": 100000,},
        "Bérlemények": {
        "Iroda": 950000,
        "Andrássy": 2300000,
        "Westend": 1300000,
        "Westend II.": 1600000,
        "Westend III": 1300000,
        "Pólus Center": 500000},
        "Irodai Kiadások": {
          "Rózsa utca - garázs": 80000,
      "Profession": 440000,
        "Telefon/internet": 220000,
        "Könyvelő": 300000,
        "Fiat 500": 190000,
        "Fix bónuszok": 430000,
        "Turcsik": 600000,
        "Takaritas": 140000,
        },
      };
    }
    
    setPromoterPayments([]);
    setAdditionalCosts([]);
    setSelectedMonth(date);
    setSelectedDate(null);
    setViewType('monthly');
  };

  const handleDateChange = (date) => {
    if(date.getFullYear() === 2024 && date.getMonth()+1 >= 9){
      categorizedCosts = categorizedCostsAfterSept;
    } else {
      categorizedCosts = {
        "Fizetések": {
        "Csereklei Dávid": 2260000,
        "Bodnár Richárd": 2260000,
        "László Emma": 340000,
        "Zentai Zsófia": 340000,
        "Nagy Alexandra": 960000,
        "Fehér Gábor": 1040000,
        "Kiss Marcell": 1110000,
        "Kövy Máté": 850000,
        "Léda Kitti": 360000,
        "Keveházi Lili": 150000,
        "Wágner Péter": 100000,},
        "Bérlemények": {
        "Iroda": 950000,
        "Andrássy": 2300000,
        "Westend": 1300000,
        "Westend II.": 1600000,
        "Westend III": 1300000,
        "Pólus Center": 500000},
        "Irodai Kiadások": {
          "Rózsa utca - garázs": 80000,
      "Profession": 440000,
        "Telefon/internet": 220000,
        "Könyvelő": 300000,
        "Fiat 500": 190000,
        "Fix bónuszok": 430000,
        "Turcsik": 600000,
        "Takaritas": 140000,
        },
      };
    }
    setPromoterPayments([]);
    setAdditionalCosts([]);
    setSelectedDate(date);
    setViewType('daily');
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedMonth || selectedDate) {
        try {
          let startDate, endDate;

          if (viewType === 'monthly') {
            startDate = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1);
            endDate = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0);
          } else {
            startDate = new Date(selectedDate);
            endDate = new Date(selectedDate);
          }

          const startStr = `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;
          const endStr = `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`;

          const options = {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem("jwt")
            }
          };
          const response = await axios.get(apiUrl + `storage.php?date=${startStr}&endDate=${endStr}`, options);
          setData(response.data);
          const response2 = await axios.get(apiUrl + `finance.php?date=${startStr}`, options);
        setAdditionalCosts(response2.data.map(cost => ({
          itemName: cost.itemName,
          itemPrice: parseFloat(cost.itemPrice),
          date: cost.date
        })));
        } catch (err) {
          setError(err.message);
        }
      }
    };
    fetchPromoters();
    fetchData();
    if(selectedMonth){
        getPromoterPayment();
    }
  }, [selectedMonth, selectedDate, viewType]);
  const getPromoterPayment = async () => {
    var data = [];
    const options = {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem("jwt")
        }
      };
       var startDate = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1);
       var endDate = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0);
      

      const startStr = `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;
      const endStr = `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`;
      const response = await axios.get(apiUrl + `storage.php?date=${startStr}&endDate=${endStr}`, options);
      data = response.data;
      var dataQty = 0;
      if(viewType === "daily"){
        const startStr = `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}`;
        dataQty = data.filter(item => item.time.includes(startStr)).map(item => parseFloat(item.price)).reduce((a, b) => a + b, 0)/12000;
      } else {
        dataQty = data.map(item => parseFloat(item.price)).reduce((a, b) => a + b, 0)/12000;
      }
        setDataQty(dataQty);
        promoters.forEach(async item => {
            var value = 0;
            const date = new Date(item["deactivation_time"]);
            const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
            // Kiszámítjuk a különbséget napokban
            var daysElapsed = date.getDate() - firstDayOfMonth.getDate() + 1;
  const currentDate = new Date();
  const isSameYear = currentDate.getFullYear() === selectedMonth.getFullYear();
  const isSameMonth = currentDate.getMonth() === selectedMonth.getMonth();
  if(isSameYear && !isSameMonth){
    daysElapsed = 30;
  }
                var totalValue = data.filter(item2 => item2.userName === item.username).map(item => parseFloat(item.price)).reduce((a, b) => a + b, 0)/12000;
                if(item["fulltime"] == "senior") {
                    value = 1000000;
                    if(date < selectedDate){
                      value = (value/30)*daysElapsed;
                    }
                    if (totalValue > 499) {
                      value += (totalValue - 400)*3000;
                    } else if (totalValue > 449) {
                      value += (totalValue - 400)*2500;
                    } else if (totalValue > 399) {
                      value += (totalValue - 400)*2000;
                    }
                  } else if (item["fulltime"] == "junior" && item.jobrole != "parttime") {
                    value = 600000;
                    if(date < selectedDate){
                      value = (value/30)*daysElapsed;
                    }
                    if (totalValue > 499) {
                      value += (totalValue - 300)*2500;
                    } else if (totalValue > 449) {
                      value += (totalValue - 300)*2000;
                    } else if (totalValue > 399) {
                      value += (totalValue - 300)*1500;
                    } else if (totalValue > 299) {
                      value += (totalValue - 300)*1000;
                    }
                  } else {
                    if (item.jobrole === "parttime") {
                      
                      value = 400000;
                      if(date < selectedDate){
                        value = (value/30)*daysElapsed;
                      }
                      if (totalValue > 399) {
                        value += (totalValue - 200)*2500;
                      } else if (totalValue > 349) {
                        value += (totalValue - 200)*2000;
                      } else if (totalValue > 299) {
                        value += (totalValue - 200)*1500;
                      } else if (totalValue > 200) {
                        value += (totalValue - 200)*1000;
                      }
                    }
                  }
                  const response = await axios.get(apiUrl + `profile.php?userId=${item.id}`, options);
                let item2 = response.data[0].bonuses.filter(item => item.yearmonth === startStr.substring(0, 7));
                item2.forEach(item => {
                    value += parseFloat(item.bonusvalue);
                }
                )
                if(date < selectedDate){
                  return;
                }
                setPromoterPayments(prevState => [...prevState, {name: item.username, value: value}]);
            }
        
        )

  }
  const fetchPromoters = () => {
    if (localStorage.getItem("isUserLoggedIn")) {
    var options = {  
    method: 'GET',
    headers: {
      
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem("jwt")
    }
    }
    var url = apiUrl + "promoter.php"
    fetch(url, options)
        .then(response => {
        return response.json()
        })
        .then(data => {
        setAllPromoters(data.filter(item => item.username !== "Super Slimmer" && item.username !== "Slim Store" && item.username !== "László Emma" && item.username !== "Zentai Zsófi").filter(item => item.jobrole != "intern"))
        })
        .catch(error => {
            window.location = '/login'
        })
    } else {
    window.location = '/login'
    }
}
  const formatPrice = (value) => {
    // Convert the plain number to a numeric value
    const numericValue = parseFloat(value);
    // Check if the numeric value is a valid number
    if (!isNaN(numericValue)) {
      // Use toLocaleString to format the number with spaces as thousand separators
      return numericValue.toLocaleString('hu');
    } else {
      // Return the original value if it's not a valid number
      return value;
    }
  };
  const handleAddCost = async () => {
    if (newCostName && newCostValue && selectedDate) {
      const cost = {
        itemName: newCostName,
        itemPrice: parseFloat(newCostValue),
        date: `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}`
      };

      // Post the cost to the server
      const options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem("jwt")
        },
        body: JSON.stringify(cost)
      };
      
      await fetch(apiUrl + `finance.php`, options);

      // Add the cost locally
      setAdditionalCosts([...additionalCosts, cost]);
      setNewCostName('');
      setNewCostValue('');
    }
  };
  const calculateSummary = () => {
    const summary = {
      totalGross: 0,
      totalNet: 0,
      items: {}
    };

    data.forEach(item => {
        const price = parseFloat(item.price);
        summary.totalGross += price;
        summary.totalNet += price / 1.27;
      
        const parsedItems = JSON.parse(item.items);
        parsedItems.forEach(product => {
          let found = false;
          Object.keys(itemMapping).forEach(key => {
            if (itemMapping[key].includes(product.itemType)) {
              if (!summary.items[key]) {
                summary.items[key] = 0;
              }
              summary.items[key] += 1;
              found = true;
            }
          });
          if (!found) {
            if (!summary.items[product.itemType]) {
              summary.items[product.itemType] = 0;
            }
            summary.items[product.itemType] += 1;
          }
        });
      });
    return summary;
  };
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  var daysElapsed = today.getDate() - firstDayOfMonth.getDate();
  const currentDate = new Date();
  const isSameYear = currentDate.getFullYear() === selectedMonth.getFullYear();
  const isSameMonth = currentDate.getMonth() === selectedMonth.getMonth();
  if(isSameYear && !isSameMonth){
    daysElapsed = 30;
  }
  const isCurrentMonth = selectedMonth.getFullYear() === today.getFullYear() && selectedMonth.getMonth() === today.getMonth();
  const calculateCosts = (netTotal, grossTotal, summaryItems, viewType) => {
    const detailedCosts = Object.entries(categorizedCosts).flatMap(([categoryName, costs]) => {
      return Object.entries(costs).map(([costName, costValue]) => ({
        name: costName,
        cost: (costValue / 30) * (viewType === 'monthly' ? daysElapsed : 1),
      }));
    });

    const zsofiExtraCost = Math.floor(grossTotal / 12000) * 300;
    const emmaExtraCost = Math.floor(grossTotal / 12000) * 300;
    // Fizetések hozzáadása a categorizedCosts listához
categorizedCosts["Fizetések"] = categorizedCosts["Fizetések"] || {};
categorizedCosts["Fizetések"]["Zentai Zsófia jutalék"] = zsofiExtraCost;
if(selectedMonth.getFullYear() === 2024 && selectedMonth.getMonth() < 9){
categorizedCosts["Fizetések"]["László Emma jutalék"] = emmaExtraCost;
}
if(promoterPayments.length > 0){
  
    promoterPayments.forEach(payment => {
        const paymentName = `${payment.name} fizetés`;
        const paymentCost = (payment.value / 30) * ( 30);
        
        // Ellenőrizd, hogy van-e már ilyen nevű fizetés a detailedCosts-ban
        if (!detailedCosts.some(item => item.name === paymentName)) {
            detailedCosts.push({ name: paymentName, cost: paymentCost });
        }

        // Add hozzá a fizetést a categorizedCosts-hoz is
        categorizedCosts["Fizetések"][paymentName] = paymentCost;
    });
}
    Object.entries(summaryItems).forEach(([itemType, quantity]) => {
      const itemCost = itemCosts[itemType];
      if (itemCost) {
        const totalCost = (itemCost * quantity) / (viewType === 'monthly' ? 1 : 1);
    
        // Ha az "Anyagköltségek" kulcs már létezik, hozzáadjuk az új elemeket.
        if (categorizedCosts["Anyagköltségek"]) {
          categorizedCosts["Anyagköltségek"][`${itemType} költség`] = totalCost;
        } else {
          // Ha az "Anyagköltségek" kulcs nem létezik, létrehozzuk, és hozzáadjuk az elemeket.
          categorizedCosts["Anyagköltségek"] = {
            [`${itemType} költség`]: totalCost
          };
        }
    
        // Opcióként a detailedCosts array-be is hozzáadhatod az új anyagköltségeket, ha szükséges.
        detailedCosts.push({ name: `${itemType} költség`, cost: totalCost });
      }
    });
    Object.entries(summaryItems).forEach(([itemType, quantity]) => {
      const itemCost = itemCosts[itemType];
      if (itemCost) {
        detailedCosts.push({ name: `${itemType} költség`, cost: (itemCost * quantity) / (viewType === 'monthly' ? 1 : 1) });
      }
    });
    const totalCost = detailedCosts.reduce((sum, item) => sum + item.cost, 0) + additionalCosts.reduce((sum, item) => sum + item.itemPrice, 0);
    return {
      netAfterCosts: netTotal - totalCost,
      detailedCosts,
      totalCost
    };
  };

  const summary = calculateSummary();
  const costDetails = calculateCosts(summary.totalNet, summary.totalGross, summary.items, viewType);

  return (
    <div className="finance">
      <h1>Pénzügyi összesítő</h1>
      <div className="button-container">
       <div> <button onClick={() => setViewType('monthly')} style={{width: "100px"}} className={viewType === "monthly" ? "active mobileInput" : "mobileInput"}>Havi nézet</button></div>
        {selectedMonth && <button onClick={() => setViewType('daily')}  style={{width: "100px"}} className={viewType === "daily" ? "active mobileInput" : "mobileInput"}>Napi nézet</button>}
      </div>
      <div className="date-picker-container">
        <DatePicker
          selected={selectedMonth}
          onChange={handleMonthChange}
          minDate={new Date(2024, 7, 1)}
          dateFormat="yyyy-MM"
          showMonthYearPicker
          placeholderText="Válassz egy hónapot"
        />
        {selectedMonth && viewType === "daily" && (
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            minDate={new Date(2024, 7, 1)}
            dateFormat="yyyy-MM-dd"
            placeholderText="Válassz egy napot"
          />
        )}
      </div>
      {viewType === 'daily' && (
  <div>
    <input
    className='mobileInput'
      type="text"
      value={newCostName}
      onChange={(e) => setNewCostName(e.target.value)}
      placeholder="Költség neve"
    />
    <input
    className='mobileInput'
      type="number"
      value={newCostValue}
      onChange={(e) => setNewCostValue(e.target.value)}
      placeholder="Költség értéke"
    />
    <button className="mobileInput submitbutton" onClick={handleAddCost}>Költség hozzáadása</button>
  </div>
)}
      {error || data.length == 0 ?  (
        <div>
        <h3 onClick={() => setIsOpenedOther(!isOpenedOther)} style={{ cursor: 'pointer' }}>
        Egyéb költségek {isOpenedOther ? '▲' : '▼'}
      </h3>
      {isOpenedOther && (<table className="cost-table">
            <thead>
              <tr>
              <th style={{fontSize: "14px"}}>Költség neve</th>
            <th style={{fontSize: "14px"}}>Összeg (HUF)</th>
            <th style={{ fontSize: '14px' }}>Dátum</th>
          </tr>
        </thead>
        <tbody>
          {additionalCosts.map((cost) => (
            <tr key={`${cost.itemName}-${cost.itemPrice}-${cost.date}`} onClick={() => handleRowClick(cost)}>
              <td>{cost.itemName}</td>
              <td>{formatPrice(cost.itemPrice)} Ft</td>
              <td>{cost.date}</td>
            </tr>
          ))}

        </tbody>
      </table>)}
      {isPopupOpen && (
    <div className="popup">
      <h2>Szerkesztés</h2>
      <div>
        <label>Költség neve:</label>
        <input
          type="text"
          name="newItemName"
          value={editedCost.newItemName}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label>Összeg (HUF):</label>
        <input
          type="number"
          name="newItemPrice"
          value={editedCost.newItemPrice}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label>Dátum (nem módosítható):</label>
        <input
          type="date"
          name="date"
          value={editedCost.date}
          readOnly
        />
      </div>
      <button onClick={handleSave}>Mentés</button>
      <button onClick={() => setIsPopupOpen(false)}>Mégse</button>
    </div>
  )}
    </div>
      ) : (
        <div className="summary-container">
            
          <h3>{viewType === 'monthly' ? 'Havi' : 'Napi'} Összesítő</h3>
          <div className="totals">
          <p style={{fontSize: "14px"}}>Eredmény:</p><p style={{fontSize: "20px", marginBottom: "5px"}}> {dataQty} db</p>
            <p style={{fontSize: "14px"}}>Bruttó bevétel:</p><p style={{fontSize: "20px", marginBottom: "5px"}}> {formatPrice(summary.totalGross.toFixed(0))} Ft</p>
            <p style={{fontSize: "14px"}}>ÁFA:</p> <p style={{fontSize: "20px", marginBottom: "5px"}}>{formatPrice((summary.totalGross-summary.totalNet).toFixed(0))} Ft</p>
            <p style={{fontSize: "14px"}}>Nettó bevétel:</p> <p style={{fontSize: "20px", marginBottom: "5px"}}>{formatPrice(summary.totalNet.toFixed(0))} Ft</p>
            <p style={{fontSize: "14px"}}>Kiadások:</p> <p style={{fontSize: "20px", marginBottom: "5px"}}>{formatPrice(costDetails.totalCost.toFixed(0))} Ft</p>
            <p style={{fontSize: "14px"}}>Profit:</p> <p style={{fontSize: "20px", marginBottom: "5px"}}>{formatPrice(costDetails.netAfterCosts.toFixed(0))} Ft</p>
            <div className="detailed-costs">
              <h3>Részletes költségek:</h3>
              {Object.entries(categorizedCosts).map(([category, costs]) => (
        <div key={category}>
          <h3 onClick={() => toggleCategory(category)} style={{ cursor: 'pointer' }}>
            {category} {openCategories[category] ? '▲' : '▼'}
          </h3>
          {openCategories[category] && (
            <table className="cost-table">
              <thead>
                <tr>
                  <th>Költség neve</th>
                  <th>Összeg (HUF)</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(costs).map(([name, value]) => (
                  <tr key={name}>
                    <td>{name}</td>
                    <td>{viewType == "monthly" ? !isCurrentMonth ? !name.includes("jutalék") && !name.includes("költség") ? (parseInt(((value/30) * daysElapsed).toFixed(0))).toLocaleString('hu') : (value).toLocaleString('hu') : (value/30).toFixed(0).toLocaleString('hu') : !name.includes("jutalék") && !name.includes("költség") ? parseInt((parseInt(value)/30)).toLocaleString('hu') : parseInt((parseInt(value))).toLocaleString('hu')} Ft</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      ))}
      <h3 onClick={() => setIsOpenedOther(!isOpenedOther)} style={{ cursor: 'pointer' }}>
            Egyéb költségek {isOpenedOther ? '▲' : '▼'}
          </h3>
          {isOpenedOther && (<table className="cost-table">
                <thead>
                  <tr>
                  <th style={{fontSize: "14px"}}>Költség neve</th>
                <th style={{fontSize: "14px"}}>Összeg (HUF)</th>
                <th style={{ fontSize: '14px' }}>Dátum</th>
              </tr>
            </thead>
            <tbody>
              {additionalCosts.map((cost) => (
                <tr key={`${cost.itemName}-${cost.itemPrice}-${cost.date}`} onClick={() => handleRowClick(cost)}>
                  <td>{cost.itemName}</td>
                  <td>{formatPrice(cost.itemPrice)} Ft</td>
                  <td>{cost.date}</td>
                </tr>
              ))}

            </tbody>
          </table>)}
          {isPopupOpen && (
        <div className="popup">
          <h2>Szerkesztés</h2>
          <div>
            <label>Költség neve:</label>
            <input
              type="text"
              name="newItemName"
              value={editedCost.newItemName}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label>Összeg (HUF):</label>
            <input
              type="number"
              name="newItemPrice"
              value={editedCost.newItemPrice}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label>Dátum (nem módosítható):</label>
            <input
              type="date"
              name="date"
              value={editedCost.date}
              readOnly
            />
          </div>
          <button onClick={handleSave}>Mentés</button>
          <button onClick={() => setIsPopupOpen(false)}>Mégse</button>
        </div>
      )}
        </div>
      </div>
    </div>
  )}
</div>
  );
}

export default FinancialSummary;