import React, { createContext, useState, useEffect } from 'react';

// Alapértelmezett színséma
const defaultTheme = {
  primary: '#60B53E', // Példa: piros
};

// Létrehozzuk a Context-et
export const ThemeContext = createContext(defaultTheme);

// A Provider komponens, amely megadja a színsémát az alkalmazás számára
export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(defaultTheme);

  // useEffect hook a cookie kezelésére
  useEffect(() => {
    // Cookie neve
    const cookieName = 'userTheme';

    // Olvassuk ki a cookie-t
    const savedTheme = getCookie(cookieName);

    // Ha van mentett színséma, használjuk azt
    if (savedTheme) {
      setTheme(JSON.parse(savedTheme));
    }
  }, []); // csak egyszer fusson le, az alkalmazás betöltésekor

  // Funkció a cookie elmentésére
  const saveThemeToCookie = (theme) => {
    // Cookie neve
    const cookieName = 'userTheme';

    // Mentjük a cookie-t
    setCookie(cookieName, JSON.stringify(theme), 30); // pl. 30 napig érvényes
  };

  // Funkció a cookie kiolvasására
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  // Funkció a cookie beállítására
  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/`;
  };

  // A színséma beállítása
  const setThemeColor = (color) => {
    const newTheme = { ...theme, primary: color };
    setTheme(newTheme);
    saveThemeToCookie(newTheme); // Mentjük a felhasználó által választott színt a cookie-ba
  };

  return (
    <ThemeContext.Provider value={{ theme, setThemeColor }}>
      {children}
    </ThemeContext.Provider>
  );
};
