import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import jwt_decode from "jwt-decode";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import { format } from 'date-fns'
import { apiUrl } from '../App/Constants';

export default function Buntetes() {
  const isMobile = /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const tableStyles = {
        display: "flex",
        flexDirection: "column",
      };
      const rowStylesGreen = {
        fontSize: "15px",
        display: isMobile ? "block" : "flex",
        justifyContent: "space-between",
        padding: "20px",
        borderRadius: "10px",
        margin: "5px",
        alignItems: "center",
        backgroundColor: "gray",
        color: "white"
      };
      
      const columnStyles = {
        flexBasis: "10%",
      };
      const headerStyles = {
        display: 'grid',
        gridTemplateColumns: '1.5fr 3fr 3fr 1fr',
        fontWeight: 'bold',
        padding: '10px',
        color: 'black',
        textAlign: 'center',
        fontSize: '14px'
      };
      
  const [allData, setAllData] = useState([])
  const [promoters, setAllPromoters] = useState([])
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const [selectedMonth, setSelectedMonth] = useState(`2024-${currentMonth.toString().padStart(2, '0')}`)
  const [selectedPromoter, setSelectedPromoter] = useState('');
  const [price, setPrice] = useState('');
  const [comment, setComment] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);

  const handlePromoterChange = (event) => {
    setSelectedPromoter(event.target.value);
  };

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSubmit = async () => {
    try {
        await axios.post(apiUrl + 'buntetes.php?name=' + selectedPromoter + '&buntetes=' + price + '&comment=' + comment + '&date=' + format(selectedDate, "yyyy-MM-dd"));
       
         window.alert("Adat sikeresen elmentve");
         setSelectedPromoter("");
         setPrice("");
         setComment("");
         fetchData(selectedMonth);
    } catch (error) {
        console.error('Error uploading image:', error);
    }
    console.log({
      selectedPromoter,
      price,
      comment,
      selectedDate,
    });
  };
  useEffect(() => {
    fetchData(selectedMonth);
    fetchPromoters();
}, [selectedMonth]);
// Function to get all unique dates for a user
function getUniqueDates(dataArray) {
    const dateSet = new Set(dataArray.map(item => item.date));
    return Array.from(dateSet);
  }
  // Generate a list of months from January 2023 to the current month
  const monthsList = [];
  for (let year = 2023; year <= currentYear; year++) {
    const maxMonth = year === currentYear ? currentMonth : 12;
    for (let month = 1; month <= maxMonth; month++) {
      const monthValue = month.toString().padStart(2, '0');
      monthsList.push(`${year}-${monthValue}`);
    }
  }
// Function to group objects by a given key
function groupBy(array, key) {
    return array.reduce((result, obj) => {
      const keyValue = obj[key];
      (result[keyValue] = result[keyValue] || []).push(obj);
      return result;
    }, {});
  }
  const fetchPromoters = () => {
    if (localStorage.getItem("isUserLoggedIn")) {
    var options = {  
    method: 'GET',
    headers: {
      
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem("jwt")
    }
    }
    var url = apiUrl + "promoter.php"
    fetch(url, options)
        .then(response => {
        return response.json()
        })
        .then(data => {
        setAllPromoters(data)
        })
        .catch(error => {
            window.location = '/login'
        })
    } else {
    window.location = '/login'
    }
}
const fetchData = (time) => {
    if (localStorage.getItem("isUserLoggedIn") === "true") {
      var decoded = jwt_decode(localStorage.getItem("jwt"));
    var options = {  
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem("jwt")
      }
    }
    var url = apiUrl + "buntetes.php?month=" + time
      fetch(url, options)
        .then(response => {
          return response.json()
        })
        .then(data => {
            // Group the objects by the "userName" key
const groupedArray = groupBy(data, "userName");

// Convert the grouped object into an array of objects
const newArray = Object.entries(groupedArray).map(([key, value]) => ({ [key]: value }));

setAllData(newArray);
        })
        .catch(error => {
         window.location = '/login'
        })
      
    } else {
      window.location = '/login'
    }
    
    
  }
  function calculateTotalValue(userData) {
    return userData.reduce((acc, item) => acc + parseInt(item.buntetes), 0);
  }

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };
  // Function to create day-based summed array
function createSummedArray(dataArray) {
  const summedArray = [];

  dataArray.forEach((item) => {
    const date = item.date; // Extract the date from the "time" field
    const price = parseInt(item.price);

    const existingEntry = summedArray.find((entry) => entry.date === date);
    if (existingEntry) {
      existingEntry.sum += price;
    } else {
      summedArray.push({ date, sum: price });
    }
  });

  return summedArray;
}
  // Calculate the totalValue/7000 and sort the allData array
const sortedAllData = allData.map(data => {
    const username = Object.keys(data)[0];
    const userData = data[username];
    const sumof = createSummedArray(userData);

    var qtyof = userData.length;
    const totalValue = calculateTotalValue(userData);
  
    return { userData, username, qtyof, totalValue };
  }); // Sort in descending order


const formatPrice = (value) => {
  // Convert the plain number to a numeric value
  const numericValue = parseFloat(value);
  // Check if the numeric value is a valid number
  if (!isNaN(numericValue)) {
    // Use toLocaleString to format the number with spaces as thousand separators
    return numericValue.toLocaleString('hu');
  } else {
    // Return the original value if it's not a valid number
    return value;
  }
};
const renderPopupforDays = (alldataofValue) => {
      return (
        <div>
        {alldataofValue.map((day) => (
          <div className='row'>
          <div className='column leftcolumn'>
          {day["date"]}
          </div>
          <div className='column leftcolumn'>{formatPrice(day["buntetes"])} Ft</div>
          <div className='column rightcolumn'>{day["comment"]}</div>
          </div>
        ))}
        </div>
      );
  };
  var decodedUser = jwt_decode(localStorage.getItem("jwt"));
  return(
    // Rendering the sorted table-like layout
<div id='masters'>
  <h2>Büntetések</h2>
  <select value={selectedMonth} onChange={handleMonthChange}>
        {monthsList.map((month) => (
          <option key={month} value={month}>
            {month}
          </option>
        ))}
      </select>
      <div className='buntetesdiv'>
      {decodedUser.priv === 1 && (
        <div>
             <div className='columnof'>
             <h5>Büntetés hozzáadása</h5>
             <select className='mobileInput' value={selectedPromoter} onChange={handlePromoterChange}>
             <option selected disabled></option>
               {promoters.map((promoter, index) => (
                 
                 <option key={index} value={promoter.username}>
                   {promoter.username}
                 </option>
               ))}
             </select>
           </div>
           <div className='columnof'>
             <input className='mobileInput' type="number" value={price} placeholder='Büntetés' onChange={handlePriceChange} />
           </div>
           <div className='columnof'>
             <input className='mobileInput' type="text" value={comment} placeholder='Indok' onChange={handleCommentChange} />
           </div>
           <div className='columnof'>
             <DatePicker selected={selectedDate} placeholderText='Dátum' onChange={handleDateChange} />
           </div>
     
           <button onClick={handleSubmit} className="mobileInput submitbutton">Küldés</button>
           </div>
)}
      
    </div>
  <div style={tableStyles}>
  <div style={headerStyles} className='onlyonPC'>
    <div></div>
    <div>Név</div>
    <div>Büntetések (db)</div>
    <div>Büntetések (Ft)</div>
  </div>
    {sortedAllData.map((data, index) => {
      const { userData, username, qtyof, totalValue } = data;
      const foundUser = promoters.find(obj => obj.username === username);
      var profilePic = apiUrl + "profile_images/placeholder.png";
      if(foundUser != null && foundUser.profile_pic != ""){
        profilePic = apiUrl + "profile_images/" + foundUser.profile_pic;
      }
      if (username !== "Slim Store" && username !== "László Emma" && username !== "Zentai Zsófi") {
        return (
          <div key={index} style={rowStylesGreen} >
            <div><img className='profilepicinMasters' src={profilePic}></img></div>
            <div style={columnStyles}>{username}</div>
            <hr className='hrc onlyonMobile'/>
            <div ><p className='onlyonMobile'>Összesen (Db): </p>{qtyof} db<Popup trigger={<button className='infobutton morebutton'>
            <FontAwesomeIcon className='faiconinfo' icon={faInfoCircle} /> Részletes
            </button>} modal>
            <div>{renderPopupforDays(userData)}</div>
        </Popup></div>
        <hr className='hrc onlyonMobile'/>
            <div ><p className='onlyonMobile'>Összesen (Ft): </p> {formatPrice(totalValue)} Ft</div>
            
            <hr className='hrc onlyonMobile'/>
            
          </div>
        );
      }
      
    })}
  </div>
</div>
  )

}


