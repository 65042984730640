import React from 'react';

export default function SubStat({ statItems, typeOf }) {
    const getUniqueType = () => {
        const uniqueTags = [];
        statItems.map(item => {
            const items = JSON.parse(item.items)
            items.map(itemOf => {
                if (typeOf === "Tok") {
                    if (itemOf.itemType.includes("Tok") || itemOf.itemType.includes("Case")) {
                    
                    if (uniqueTags.indexOf(itemOf.mobileType) === -1 && !uniqueTags.includes(itemOf.itemType + " - " + itemOf.mobileType)) {
                        uniqueTags.push(itemOf.itemType + " - " + itemOf.mobileType)
                    }
                }
                }
            })
        })
        return uniqueTags
    }
    const getCountOfItem = () => {
        var count = 0;
    
        const typeMapping = {
            'Slim UV': ['Slim UV','UV fólia'],
            'Slim Privacy Matt': ['Slim Privacy Matt','Privacy fólia'],
            'Slim Self-healing': ['Slim Self-healing','Self-healing fólia'],
            'Slim HD': ['Slim HD','Sima fólia'],
            'Garancia 50': ['Garancia 50'],
            'Garancia 100': ['Garancia 100'],
            'Slim Pad HD': ['iPad HD fólia', 'Slim Pad HD'],
            'Slim Pad Matt': ['iPad matt fólia','Slim Pad Matt'],
            'Slim Power': ['Powerbank','Slim Power'],
            'Tok': ['Tok', "Slim Case", "Slim Pro Case", "Slim Pro Magsafe Case"],
            'Fekete kártya': ['Fekete kártya'],
            'Fehér kártya': ['Fehér kártya'],
            'Tisztítás kártya': ['Tisztítás kártya'],
            'Szerviz Ezüst 5% kártya': ['Szerviz Ezüst 5% kártya'],
            'Szerviz Arany 10% kártya': ['Szerviz Arany 10% kártya'],
        };
    
        statItems.map(item => {
            const items = JSON.parse(item.items).filter(itemos => {
                // Ellenőrizzük az itemType értékeket a typeMapping alapján
                return typeMapping[typeOf] && typeMapping[typeOf].includes(itemos.itemType);
            });
    
            count += items.length; // Összeszámoljuk a megfelelő elemeket
        });
    
        return count;
    };
    const getCountOfType = type => {
    let count = 0;
    const [itemType, mobileType] = type.split(" - ");
    
    statItems.forEach(item => {
        const items = JSON.parse(item.items);
        items.forEach(itemOf => {
            if ((itemOf.itemType.includes("Tok") || itemOf.itemType.includes("Case")) &&
                itemOf.mobileType === mobileType && 
                itemOf.itemType === itemType) {
                    count++;
            }
        });
    });
    
    return count;
}
    return (
        <div>
            <div className='centerDiv'><h2 className='statTitle'>{typeOf} ({getCountOfItem()} db)</h2>
                {getUniqueType().map(item1 => {
                    if (!typeOf.includes("kártya")) {
                                            return (     
                                        <div>
                                            <p className="productType pay">{getCountOfType(item1)} db - {item1}</p>
                                            </div>
                                        
                                        )
                    
                }
                
                })
                }
            </div>
            <hr></hr>
        </div>
    )
}