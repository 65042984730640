import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import '../../App.css';
import { apiUrl } from './Constants';

var categorizedCosts = {
    "Fizetések": {
        "Rácz Dávid": 200000,
    },
  "Kiadások": {
  "Tárhely": 12000},
};

const itemCosts = {
  "Slim Case": 700,
  "Slim Pro Case": 1000,
  "Slim Pro Magsafe Case": 1400,
  "Slim UV": 700,
  "Slim Privacy Matt": 700,
  "Slim Self-healing": 400,
  "Slim HD": 200,
  "Slim Pad HD": 500,
  "Slim Pad Matt": 500,
  "Slim Power": 2000,
  "Slim HD Üvegfólia": 600,
  "Slim Privacy Üvegfólia": 600,
};

const itemMapping = {
  "Slim Self-healing": ["Self-healing fólia", "Slim Self-healing", "SELF-HEALING FÓLIA"],
  "Slim UV": ["UV fólia", "Slim UV", "UV FÓLIA"],
  "Slim Privacy Matt": ["Slim Privacy Matt", "Privacy Matt", "Privacy fólia", "Matt fólia", "MATT PRIVACY FÓLIA"],
  "Slim Pro Case": ["Pro case", "Slim Pro Case", "ÁTLÁTSZÓ TOK"],
  "Slim Pro Magsafe Case": ["Magsafe case", "Slim Pro Magsafe Case", "MAGSAFE TOK"],
  "Slim Case": ["Fekete tok", "Slim Case", "Tok", "Slim Case Garanciális", "SZILIKON TOK"],
  "Slim Pad Matt": ["iPad matt fólia", "Matt tablet fólia", "Slim Pad Matt", "TABLET FÓLIA"],
  "Slim Pad HD": ["Hd tablet fólia", "Slim Pad HD", "Hd tablet fólia", "iPad HD fólia" , "TABLET FÓLIA"],
  "Slim HD": ["Hd (sarga)", "Sima fólia", "Slim HD", "Slim HD Garanciális", "HÁTLAPI FÓLIA"],
  "Slim Power": ["Powerbank", "POWERBANK"],
  "Slim HD Üvegfólia": ["HD üvegfólia", "HD Üvegfólia", "HD ÜVEGFÓLIA"],
  "Slim Privacy Üvegfólia": ["Privacy üvegfólia", "Privacy Üvegfólia", "PRIVACY ÜVEGFÓLIA"]
};

const FinancialWebshopSummary = () => {
  const today = new Date();
  const [selectedMonth, setSelectedMonth] = useState(today);
  const [selectedDate, setSelectedDate] = useState(today);
  const [viewType, setViewType] = useState('daily');
  const [data, setData] = useState([]);
  const [dataQty, setDataQty] = useState([]);
  const [error, setError] = useState(null);
  const [newCostName, setNewCostName] = useState('');
  const [newCostValue, setNewCostValue] = useState('');
  const [additionalCosts, setAdditionalCosts] = useState([]);
  const [openCategories, setOpenCategories] = useState({});
  const [isOpenedOther, setIsOpenedOther] = useState(false);
  const [selectedCost, setSelectedCost] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [editedCost, setEditedCost] = useState({ oldItemName: '', oldItemPrice: '', date: '', newItemName: '', newItemPrice: '' });

  const handleRowClick = (cost) => {
    setSelectedCost(cost);
    setEditedCost({
      oldItemName: cost.itemName,
      oldItemPrice: cost.itemPrice,
      date: cost.date,
      newItemName: cost.itemName,  // Alapból az eredeti értékeket adjuk meg
      newItemPrice: cost.itemPrice
    });
    setIsPopupOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedCost({ ...editedCost, [name]: value });
  };

  const handleSave = () => {
    updateCost(editedCost);
    setIsPopupOpen(false);
  };

  const updateCost = (cost) => {
    fetch(apiUrl + `financewebshop.php`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        oldItemName: cost.oldItemName,
        oldItemPrice: cost.oldItemPrice.toString(),
        date: cost.date,
        newItemName: cost.newItemName,
        newItemPrice: cost.newItemPrice.toString(),
      }),
    })
      .then(data => {
        const updatedCosts = additionalCosts.map(cost => {
          if (cost.itemName === editedCost.oldItemName && 
              cost.itemPrice === editedCost.oldItemPrice && 
              cost.date === editedCost.date) {
            return {
              ...cost,
              itemName: editedCost.newItemName,
              itemPrice: parseInt(editedCost.newItemPrice),
            };
          }
          return cost;
        });
        setAdditionalCosts(updatedCosts);
      })
      .catch(error => console.error('Error updating cost:', error));
  };
  const toggleCategory = (category) => {
    setOpenCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };
  const handleMonthChange = (date) => {
    setData([]);
    if(date.getFullYear() === 2024 && date.getMonth() < 9) {
    categorizedCosts = {
      "Fizetések": {
          "Rácz Dávid": 200000,
      },
    "Kiadások": {
    "Tárhely": 12000},
  };
} else {
    categorizedCosts = {
      "Fizetések": {
          "Rácz Dávid": 500000,
      },
    "Kiadások": {
    "Tárhely": 12000},
  };
}
    setAdditionalCosts([]);
    setSelectedMonth(date);
    setSelectedDate(null);
    setViewType('monthly');
  };

  const handleDateChange = (date) => {
    if(date.getFullYear() === 2024 && date.getMonth() < 9) {

    categorizedCosts = {
      "Fizetések": {
          "Rácz Dávid": 200000,
      },
    "Kiadások": {
    "Tárhely": 12000},
  };
} else {
    categorizedCosts = {
      "Fizetések": {
          "Rácz Dávid": 500000,
      },
    "Kiadások": {
    "Tárhely": 12000},
  };
}
    setData([]);
    setAdditionalCosts([]);
    setSelectedDate(date);
    setViewType('daily');
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedMonth || selectedDate) {
        try {
          let startDate, endDate;

          if (viewType === 'monthly') {
            startDate = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1);
            endDate = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0);
          } else {
            startDate = new Date(selectedDate);
            endDate = new Date(selectedDate);
          }

          const startStr = `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;
          const endStr = `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`;
          const options = {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem("jwt")
            }
          };
          const response2 = await axios.get(apiUrl + `financewebshop.php?date=${startStr}`, options);
        setAdditionalCosts(response2.data.map(cost => ({
          itemName: cost.itemName,
          itemPrice: parseFloat(cost.itemPrice),
          date: cost.date
        })));
          const response = await axios.get(apiUrl + `webshoporders.php?start_date=${startStr}&end_date=${endStr}`);
          if (response.data.message === "No orders found") {
            setError('Nincs eladás az adott időszakban.');
            return;
          } else {
            setError(null);
            setData(response.data.data.flatMap(order => order.items));
          }
          
        } catch (err) {
          setError(err.message);
        }
      }
    };
    fetchData();
  }, [selectedMonth, selectedDate, viewType]);
  const formatPrice = (value) => {
    // Convert the plain number to a numeric value
    const numericValue = parseFloat(value);
    // Check if the numeric value is a valid number
    if (!isNaN(numericValue)) {
      // Use toLocaleString to format the number with spaces as thousand separators
      return numericValue.toLocaleString('hu');
    } else {
      // Return the original value if it's not a valid number
      return value;
    }
  };
  const handleAddCost = async () => {
    if (newCostName && newCostValue && selectedDate) {
      const cost = {
        itemName: newCostName,
        itemPrice: parseFloat(newCostValue),
        date: `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}`
      };

      // Post the cost to the server
      const options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem("jwt")
        },
        body: JSON.stringify(cost)
      };
      
      await fetch(apiUrl + `financewebshop.php`, options);

      // Add the cost locally
      setAdditionalCosts([...additionalCosts, cost]);
      setNewCostName('');
      setNewCostValue('');
    }
  };
  const calculateSummary = () => {
    const summary = {
      totalGross: 0,
      totalNet: 0,
      items: {}
    };

    data.forEach(item => {
        const price = parseFloat(item.total);
        summary.totalGross += price;
        summary.totalNet += price / 1.27;
        let found = false;
        Object.keys(itemMapping).forEach(key => {
            if (itemMapping[key].includes(item.product_name)) {
              if (!summary.items[key]) {
                summary.items[key] = 0;
              }
              summary.items[key] += 1;
              found = true;
            }
          });
          if (!found) {
            if (!summary.items[item.product_name]) {
              summary.items[item.product_name] = 0;
            }
            summary.items[item.product_name] += 1;
          }
      });
    return summary;
  };
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  var daysElapsed = today.getDate() - firstDayOfMonth.getDate();
  if(today.getMonth() !== selectedMonth.getMonth()) {
    daysElapsed = 30;
  }
  const isCurrentMonth = selectedMonth.getFullYear() === today.getFullYear() && selectedMonth.getMonth() === today.getMonth();
  const calculateCosts = (netTotal, grossTotal, summaryItems, viewType) => {
    const detailedCosts = Object.entries(categorizedCosts).flatMap(([categoryName, costs]) => {
        return Object.entries(costs).map(([costName, costValue]) => ({
          name: costName,
          cost: (costValue / (costName.includes("költség") && viewType !== 'monthly' ? 1 : 30)) * (viewType === 'monthly' ? daysElapsed : 1),
        }));
      });
      
      // Duplikációk eltávolítása a detailedCosts tömbből
      const uniqueCosts = [];
      const costNames = new Set();
      
      detailedCosts.forEach(item => {
        if (!costNames.has(item.name)) {
          costNames.add(item.name);
          uniqueCosts.push(item);
        }
      });
    Object.entries(summaryItems).forEach(([itemType, quantity]) => {
      const itemCost = itemCosts[itemType];
      if (itemCost) {
        const totalCost = (itemCost * quantity) / (viewType === 'monthly' ? 1 : 1);
        // Ha az "Anyagköltségek" kulcs már létezik, hozzáadjuk az új elemeket.
        if (categorizedCosts["Anyagköltségek"]) {
          categorizedCosts["Anyagköltségek"][`${itemType} költség`] = totalCost;
        } else {
          // Ha az "Anyagköltségek" kulcs nem létezik, létrehozzuk, és hozzáadjuk az elemeket.
          categorizedCosts["Anyagköltségek"] = {
            [`${itemType} költség`]: totalCost
          };
        }
    
      }
    });
    const totalCost = uniqueCosts.reduce((sum, item) => sum + item.cost, 0) + additionalCosts.reduce((sum, item) => sum + item.itemPrice, 0);
    return {
      netAfterCosts: netTotal - totalCost,
      detailedCosts,
      totalCost
    };
  };

  const summary = calculateSummary();
  const costDetails = calculateCosts(summary.totalNet, summary.totalGross, summary.items, viewType);

  return (
    <div className="finance">
      <h1>Webshop pénzügyi összesítő</h1>
      <div className="button-container">
       <div> <button onClick={() => setViewType('monthly')} style={{width: "100px"}} className={viewType === "monthly" ? "active mobileInput" : "mobileInput"}>Havi nézet</button></div>
        {selectedMonth && <button onClick={() => setViewType('daily')}  style={{width: "100px"}} className={viewType === "daily" ? "active mobileInput" : "mobileInput"}>Napi nézet</button>}
      </div>
      <div className="date-picker-container">
        <DatePicker
          selected={selectedMonth}
          onChange={handleMonthChange}
          minDate={new Date(2024, 7, 1)}
          dateFormat="yyyy-MM"
          showMonthYearPicker
          placeholderText="Válassz egy hónapot"
        />
        {selectedMonth && viewType === "daily" && (
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            minDate={new Date(2024, 7, 1)}
            dateFormat="yyyy-MM-dd"
            placeholderText="Válassz egy napot"
          />
        )}
      </div>
      {error || data.length == 0 ?  (
        <div>
          <div>
    <input
    className='mobileInput'
      type="text"
      value={newCostName}
      onChange={(e) => setNewCostName(e.target.value)}
      placeholder="Költség neve"
    />
    <input
    className='mobileInput'
      type="number"
      value={newCostValue}
      onChange={(e) => setNewCostValue(e.target.value)}
      placeholder="Költség értéke"
    />
    <button className="mobileInput submitbutton" onClick={handleAddCost}>Költség hozzáadása</button>
  </div>
        <h3 onClick={() => setIsOpenedOther(!isOpenedOther)} style={{ cursor: 'pointer' }}>
        Egyéb költségek {isOpenedOther ? '▲' : '▼'}
      </h3>
      {isOpenedOther && (<table className="cost-table">
            <thead>
              <tr>
              <th style={{fontSize: "14px"}}>Költség neve</th>
            <th style={{fontSize: "14px"}}>Összeg (HUF)</th>
            <th style={{ fontSize: '14px' }}>Dátum</th>
          </tr>
        </thead>
        <tbody>
          {additionalCosts.map((cost) => (
            <tr key={`${cost.itemName}-${cost.itemPrice}-${cost.date}`} onClick={() => handleRowClick(cost)}>
              <td>{cost.itemName}</td>
              <td>{formatPrice(cost.itemPrice)} Ft</td>
              <td>{cost.date}</td>
            </tr>
          ))}

        </tbody>
      </table>)}
      {isPopupOpen && (
    <div className="popup">
      <h2>Szerkesztés</h2>
      <div>
        <label>Költség neve:</label>
        <input
          type="text"
          name="newItemName"
          value={editedCost.newItemName}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label>Összeg (HUF):</label>
        <input
          type="number"
          name="newItemPrice"
          value={editedCost.newItemPrice}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label>Dátum (nem módosítható):</label>
        <input
          type="date"
          name="date"
          value={editedCost.date}
          readOnly
        />
      </div>
      <button onClick={handleSave}>Mentés</button>
      <button onClick={() => setIsPopupOpen(false)}>Mégse</button>
    </div>
  )}
    </div>
      ) : (
        <div className="summary-container">
            {viewType === 'daily' && (
  <div>
    <input
    className='mobileInput'
      type="text"
      value={newCostName}
      onChange={(e) => setNewCostName(e.target.value)}
      placeholder="Költség neve"
    />
    <input
    className='mobileInput'
      type="number"
      value={newCostValue}
      onChange={(e) => setNewCostValue(e.target.value)}
      placeholder="Költség értéke"
    />
    <button className="mobileInput submitbutton" onClick={handleAddCost}>Költség hozzáadása</button>
  </div>
)}
          <h3>{viewType === 'monthly' ? 'Havi' : 'Napi'} Összesítő</h3>
          <div className="totals">
          <p style={{fontSize: "14px"}}>Eredmény:</p><p style={{fontSize: "20px", marginBottom: "5px"}}> {Number.isInteger(summary.totalGross / 11990) ? (summary.totalGross / 11990) : (summary.totalGross / 11990).toFixed(2)} db</p>
            <p style={{fontSize: "14px"}}>Bruttó bevétel:</p><p style={{fontSize: "20px", marginBottom: "5px"}}> {formatPrice(summary.totalGross.toFixed(0))} Ft</p>
            <p style={{fontSize: "14px"}}>ÁFA:</p> <p style={{fontSize: "20px", marginBottom: "5px"}}>{formatPrice((summary.totalGross-summary.totalNet).toFixed(0))} Ft</p>
            <p style={{fontSize: "14px"}}>Nettó bevétel:</p> <p style={{fontSize: "20px", marginBottom: "5px"}}>{formatPrice(summary.totalNet.toFixed(0))} Ft</p>
            <p style={{fontSize: "14px"}}>Kiadások:</p> <p style={{fontSize: "20px", marginBottom: "5px"}}>{formatPrice(costDetails.totalCost.toFixed(0))} Ft</p>
            <p style={{fontSize: "14px"}}>Profit:</p> <p style={{fontSize: "20px", marginBottom: "5px"}}>{formatPrice(costDetails.netAfterCosts.toFixed(0))} Ft</p>
            <div className="detailed-costs">
              <h3>Részletes költségek:</h3>
              {Object.entries(categorizedCosts).map(([category, costs]) => (
        <div key={category}>
          <h3 onClick={() => toggleCategory(category)} style={{ cursor: 'pointer' }}>
            {category} {openCategories[category] ? '▲' : '▼'}
          </h3>
          {openCategories[category] && (
            <table className="cost-table">
              <thead>
                <tr>
                  <th>Költség neve</th>
                  <th>Összeg (HUF)</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(costs).map(([name, value]) => (
                  <tr key={name}>
                    <td>{name}</td>
                    <td>{viewType == "monthly" ? isCurrentMonth ? !name.includes("jutalék") && !name.includes("költség") ? (parseInt(((value/30) * daysElapsed).toFixed(0))).toLocaleString('hu') : (value).toLocaleString('hu') : (value/30).toFixed(0).toLocaleString('hu') : !name.includes("jutalék") && !name.includes("költség") ? parseInt((parseInt(value)/30)).toLocaleString('hu') : parseInt((parseInt(value))).toLocaleString('hu')} Ft</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      ))}
      <h3 onClick={() => setIsOpenedOther(!isOpenedOther)} style={{ cursor: 'pointer' }}>
            Egyéb költségek {isOpenedOther ? '▲' : '▼'}
          </h3>
          {isOpenedOther && (<table className="cost-table">
                <thead>
                  <tr>
                  <th style={{fontSize: "14px"}}>Költség neve</th>
                <th style={{fontSize: "14px"}}>Összeg (HUF)</th>
                <th style={{ fontSize: '14px' }}>Dátum</th>
              </tr>
            </thead>
            <tbody>
              {additionalCosts.map((cost) => (
                <tr key={`${cost.itemName}-${cost.itemPrice}-${cost.date}`} onClick={() => handleRowClick(cost)}>
                  <td>{cost.itemName}</td>
                  <td>{formatPrice(cost.itemPrice)} Ft</td>
                  <td>{cost.date}</td>
                </tr>
              ))}

            </tbody>
          </table>)}
          {isPopupOpen && (
        <div className="popup">
          <h2>Szerkesztés</h2>
          <div>
            <label>Költség neve:</label>
            <input
              type="text"
              name="newItemName"
              value={editedCost.newItemName}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label>Összeg (HUF):</label>
            <input
              type="number"
              name="newItemPrice"
              value={editedCost.newItemPrice}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label>Dátum (nem módosítható):</label>
            <input
              type="date"
              name="date"
              value={editedCost.date}
              readOnly
            />
          </div>
          <button onClick={handleSave}>Mentés</button>
          <button onClick={() => setIsPopupOpen(false)}>Mégse</button>
        </div>
      )}
        </div>
      </div>
    </div>
  )}
</div>
  );
}

export default FinancialWebshopSummary;